import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox, Add } from "@mui/icons-material";
import { CustomModal } from "../CustomModalInner";
import React, { useState } from "react";
import { useSpecialtiesQuery } from "../../hooks/queries/useMedicalServicesQuery";
import { collection, doc, setDoc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { toast } from "react-toastify";

export interface SpecialtyAutoCompleteProps {
  value: string[];
  setValue: (value: string[]) => void;
}

export const SpecialtyAutoComplete: React.FC<SpecialtyAutoCompleteProps> = ({
  value,
  setValue,
}) => {
  const [addSpecialtyModalActive, setAddSpecialtyModalActive] = useState(false);
  const [newSpecialty, setNewSpecialty] = useState<string>("");

  const { data: specialtiesQuery, isLoading, refetch } = useSpecialtiesQuery();

  const handleAddSpecialty = async () => {
    if (!newSpecialty) return;
    try {
      const docRef = doc(
        collection(fireStoreDb, "medical_services"),
        newSpecialty
      );
      await setDoc(docRef, { id: newSpecialty, type: "specialty" });
      setValue([...value, docRef.id]);
      setNewSpecialty("");
      setAddSpecialtyModalActive(false);
      refetch();
      toast.success("Nova especialização adicionada com sucesso!");
    } catch (error) {
      console.error("Error adding new specialty: ", error);
      toast.error("Erro ao adicionar nova especialização");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Autocomplete
        multiple
        options={specialtiesQuery || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.id}
          </li>
        )}
        value={
          specialtiesQuery?.filter((specialty) =>
            value.includes(specialty.id)
          ) || []
        }
        onChange={(_event, newValue) => {
          setValue(newValue.map((v) => v.id));
        }}
        renderInput={(params) => (
          <TextField {...params} label="Especializações" />
        )}
        fullWidth
        loading={isLoading}
      />
      <div>
        <IconButton
          color="primary"
          onClick={() => setAddSpecialtyModalActive(true)}
          children={<Add />}
        />
      </div>
      <CustomModal
        open={addSpecialtyModalActive}
        onClose={() => setAddSpecialtyModalActive(false)}
      >
        <div className="p-5 d-flex flex-column gap-3">
          <TextField
            autoComplete="new-password"
            required
            label="Nova Especialidade"
            variant="outlined"
            value={newSpecialty}
            onChange={({ target }) => setNewSpecialty(target.value)}
          />
          <Button variant="contained" onClick={handleAddSpecialty}>
            Adicionar
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};
