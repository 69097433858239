import { Autocomplete, AutocompleteItem, Button } from "@nextui-org/react";
import { PreferenceLabel } from "../../Atoms/PreferenceLabel";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { compareStrings } from "../../../utils/compareStrings";

interface LocationPreferenceProps {
  isLoadingStates: boolean;
  isLoadingCities: boolean;
  handleStateChange: (stateId: string) => void;
  handleCityChange: (cityId: string) => void;
  selectedState?: { id: string };
  selectedCity?: { id: string };
  stateItems: { key: string; label: string }[];
  cityItems: { key: string; label: string }[];
  isManualLocationMutationLoading: boolean;
  handleLocationUpdate: () => void;
  user: AuthContextUserProps;
}

export const LocationPreference: React.FC<LocationPreferenceProps> = ({
  isLoadingStates,
  isLoadingCities,
  handleStateChange,
  handleCityChange,
  selectedState,
  selectedCity,
  stateItems,
  cityItems,
  isManualLocationMutationLoading,
  handleLocationUpdate,
  user,
}) => (
  <div className="flex flex-col gap-2">
    <PreferenceLabel icon="mapPin">Localização</PreferenceLabel>
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <Autocomplete
          size="lg"
          label="Selecione o seu estado"
          variant="bordered"
          listboxProps={{
            emptyContent: "Estado não encontrado.",
          }}
          isLoading={isLoadingStates}
          onSelectionChange={
            handleStateChange as (key: React.Key | null) => void
          }
          value={selectedState?.id.toString()}
          selectedKey={selectedState?.id.toString()}
          defaultItems={stateItems.sort((a, b) =>
            compareStrings(a.label, b.label)
          )}
          classNames={{
            clearButton: "w-12",
          }}
        >
          {(item: any) => (
            <AutocompleteItem
              key={item.key.toString()}
              value={item.key.toString()}
              color="secondary"
              variant="flat"
            >
              {item.label}
            </AutocompleteItem>
          )}
        </Autocomplete>
        <Autocomplete
          size="lg"
          label="Selecione a sua cidade"
          variant="bordered"
          listboxProps={{
            emptyContent: "Cidade não encontrada.",
          }}
          isLoading={isLoadingCities}
          onSelectionChange={
            handleCityChange as (key: React.Key | null) => void
          }
          value={selectedState?.id.toString()}
          selectedKey={selectedCity?.id.toString()}
          isDisabled={!selectedState}
          defaultItems={cityItems.sort((a, b) =>
            compareStrings(a.label, b.label)
          )}
          classNames={{
            clearButton: "w-12",
          }}
        >
          {(item: any) => (
            <AutocompleteItem
              key={item.key}
              value={item.key}
              color="secondary"
              variant="flat"
            >
              {item.label}
            </AutocompleteItem>
          )}
        </Autocomplete>
      </div>
      <Button
        size="lg"
        variant="solid"
        color="primary"
        className="font-medium"
        isLoading={isManualLocationMutationLoading}
        isDisabled={
          !selectedState ||
          !selectedCity ||
          user.location?.city?.id === selectedCity.id
        }
        onPress={handleLocationUpdate}
      >
        Confirmar Localização
      </Button>
    </div>
  </div>
);
