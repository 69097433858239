export const formatPhoneNumber = (phoneNumber: string) => {
  const regex = /^\+55(\d{2})(\d{4,5})(\d{4})$/;
  const match = phoneNumber.match(regex);

  if (match) {
    const [, areaCode, firstPart, secondPart] = match;
    return `+55 (${areaCode}) ${firstPart}-${secondPart}`;
  }

  return phoneNumber;
};
