import { CircularProgress } from "@nextui-org/react";

export const Loading = () => {
  return (
    <main className="flex flex-col justify-center items-center min-h-svh gap-4">
      <h1 className="text-default-900 font-bold text-5xl">ZIP SAÚDE</h1>
      <CircularProgress aria-label="Ícone de carregamento circular" />
    </main>
  );
};
