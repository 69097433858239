import { PreferenceIcon } from "../PreferenceIcon";

interface PreferenceLabelProps {
  children: React.ReactNode;
  icon: "mapPin" | "clock" | "bell";
}

export const PreferenceLabel: React.FC<PreferenceLabelProps> = ({
  children,
  icon,
}) => (
  <span className="flex items-center gap-0.5 text-default-700 text-xs font-medium px-1">
    <PreferenceIcon name={icon} className="w-4 text-default-700" />
    {children}
  </span>
);
