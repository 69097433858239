import React from "react";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { Schedule } from "../../../models/Schedule";

interface ExamSelectionProps {
  selectedExam: string | null;
  offeredExams: Schedule["exams"];
  onExamChange: (examId: string | null) => void;
}

export const ExamSelection: React.FC<ExamSelectionProps> = ({
  selectedExam,
  offeredExams,
  onExamChange,
}) => (
  <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
    <div className="w-full bg-default-100 rounded-medium p-2">
      <h2 className="text-default-700 font-semibold text-sm">Exame</h2>
    </div>
    {offeredExams && Object.keys(offeredExams).length > 0 ? (
      <Autocomplete
        size="lg"
        radius="md"
        variant="bordered"
        label="Selecione um exame"
        selectedKey={selectedExam}
        onSelectionChange={(value) => onExamChange(value as string)}
        classNames={{
          clearButton: "w-12",
        }}
      >
        {Object.entries(offeredExams).map(([examId, examDetails]) => (
          <AutocompleteItem
            key={examId}
            color="secondary"
            variant="flat"
            textValue={examDetails.name || ""}
          >
            <div className="flex justify-between items-center">
              <span>{examDetails.name}</span>
              {examDetails.price && (
                <span className="text-success-600 font-semibold">
                  {examDetails.price}
                </span>
              )}
            </div>
          </AutocompleteItem>
        ))}
      </Autocomplete>
    ) : (
      <p className="text-sm text-danger-600">
        Nenhum exame disponível no momento.
      </p>
    )}
  </div>
);
