import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    overflow-x: auto;
    text-align: center;

    > * {
        min-width: 90px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &::-webkit-scrollbar {
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const TimeSelectionCell = styled.div`
    background-color: #eef4fd;
    font-weight: bold;
    color: #427ecc;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    &.disabled {
        background-color: #eeeeee;
        color: #c3c3c3;
        opacity: 0.8;
    }

    &.selected {
        background-color: #427ecc;
        color: white;
    }
`;
