import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useMemo, useState } from "react";
import { useAuth } from "../../../contexts/auth.context";
import { toast } from "react-toastify";

interface PhoneVerificationModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

export const PhoneVerificationModal: React.FC<PhoneVerificationModalProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string>("");

  const { handleSendPhoneVerificationCode, handleVerifyPhoneCode } = useAuth();

  const validatePhoneNumber = (value: string) =>
    value.match(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/i);

  const isInvalid = useMemo(() => {
    return phoneNumber !== "" && !validatePhoneNumber(phoneNumber);
  }, [phoneNumber]);

  const handleSendVerificationCode = async () => {
    setIsLoading(true);
    try {
      const verId = await handleSendPhoneVerificationCode(phoneNumber);
      setVerificationId(verId);
      setIsCodeSent(true);

      toast.success("Código de verificação enviado com sucesso!");
    } catch (error: any) {
      switch (error.message) {
        case "Firebase: Error (auth/too-many-requests).":
          toast.error(
            "Muitas requisições foram feitas, aguarde alguns minutos e tente novamente."
          );
          break;

        default:
          toast.error("Ocorreu um erro ao enviar o código de verificação.");
          break;
      }
      console.error("Error sending verification code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    setIsLoading(true);
    try {
      await handleVerifyPhoneCode(
        phoneNumber,
        verificationId,
        verificationCode
      );
      toast.success("Número de telefone verificado com sucesso!");
      onOpenChange();
    } catch (error: any) {
      console.error("Error verifying code:", error.message);

      switch (error.message) {
        case "FirebaseError: Firebase: Error (auth/account-exists-with-different-credential).":
          toast.error(
            "Uma conta já foi vinculada com esse número de telefone."
          );
          break;
        case "FirebaseError: Firebase: Error (auth/code-expired).":
          toast.error("O seu código expirou. Por favor, tente novamente.");
          break;
        case "FirebaseError: Firebase: Error (auth/too-many-requests).":
          toast.error(
            "Muitas requisições foram feitas, aguarde alguns minutos e tente novamente."
          );
          break;

        default:
          toast.error(
            "Erro ao verificar o código. Por favor, tente novamente."
          );
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setPhoneNumber("");
    setVerificationCode("");
    setIsCodeSent(false);
    setVerificationId("");
    onOpenChange();
  };

  return (
    <Modal
      size="lg"
      backdrop="blur"
      isOpen={isOpen}
      placement="center"
      onOpenChange={onOpenChange}
      isDismissable={false}
      classNames={{
        backdrop: "bg-black/75",
      }}
    >
      <ModalContent>
        <>
          <ModalHeader>Verifique o seu telefone</ModalHeader>
          <ModalBody>
            <span>
              Um SMS será enviado para o número informado. Digite o código
              recebido na próxima etapa para confirmar o número de celular.
            </span>
            <Input
              size="lg"
              label="Telefone"
              variant="bordered"
              type="tel"
              pattern={
                "[0-9]{2} [0-9]{5} [0-9]{4}" || "[0-9]{2} [0-9]{4} [0-9]{4}"
              }
              maxLength={11}
              placeholder="(XX) XXXXX-XXXX"
              isInvalid={isInvalid}
              isDisabled={isCodeSent}
              color={isInvalid ? "danger" : "default"}
              value={phoneNumber}
              onValueChange={setPhoneNumber}
              errorMessage="Preencha um número de telefone válido"
              startContent={
                <div className="pointer-events-none flex items-center">
                  <span className="text-default-400 text-sm">+55</span>
                </div>
              }
              classNames={{ input: "text-default-700" }}
              isRequired
            />
            {isCodeSent && (
              <Input
                label="Código de Verificação"
                variant="bordered"
                type="text"
                size="lg"
                maxLength={6}
                placeholder="Digite o código de 6 dígitos"
                value={verificationCode}
                onValueChange={setVerificationCode}
                classNames={{ input: "text-default-700" }}
                isRequired
              />
            )}
          </ModalBody>
          <ModalFooter className="gap-4">
            <Button color="danger" variant="light" onPress={handleClose}>
              Cancelar
            </Button>
            {!isCodeSent ? (
              <Button
                color="primary"
                onPress={handleSendVerificationCode}
                isLoading={isLoading}
                isDisabled={isInvalid || !phoneNumber || isLoading}
              >
                Enviar SMS
              </Button>
            ) : (
              <Button
                color="primary"
                onPress={handleVerifyCode}
                isLoading={isLoading}
                isDisabled={
                  !verificationCode ||
                  verificationCode.length !== 6 ||
                  isLoading
                }
              >
                Verificar Código
              </Button>
            )}
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
};
