import { useQueries } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { UserInformation } from "../../models/UserInformation";

export const useMultipleUsersQuery = (userIds: string[]) => {
  const queries = useQueries({
    queries: userIds.map((userId) => ({
      queryKey: ["users", userId],
      queryFn: async () => {
        const docRef = doc(fireStoreDb, "users", userId);
        const docSnap = await getDoc(docRef);
        return { id: userId, ...docSnap.data() } as UserInformation & {
          id: string;
        };
      },
      enabled: Boolean(userId),
    })),
  });

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);
  const error = queries.find((query) => query.error)?.error;
  const data = queries
    .map((query) => query.data)
    .filter(Boolean) as (UserInformation & { id: string })[];

  return {
    isLoading,
    isError,
    error,
    data,
    queries,
  };
};
