import React, { useState, createRef, useEffect } from "react";
import { Button } from "@mui/material";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../services/firebase/firebase";
import { toast } from "react-toastify";
import { ProviderInformation } from "../../models/ProviderInformation";

interface ImageUploadProps {
  details: ProviderInformation;
  setDetails: React.Dispatch<React.SetStateAction<ProviderInformation>>;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  details,
  setDetails,
}) => {
  const imageUploadRef = createRef<HTMLInputElement>();
  const [image2Upload, setImage2Upload] = useState<File | null>(null);
  const [progresspercent, setProgresspercent] = useState(0);

  useEffect(() => {
    if (!image2Upload) return;

    const filename = `${details.id}-profile-img`;
    const storageRef = ref(storage, `files/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, image2Upload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        console.error(error);
        toast.error("Erro ao enviar imagem");
      },
      async () => {
        toast.success("Imagem enviada com sucesso!");
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
        setDetails((prevDetails) => ({
          ...prevDetails,
          imageUrl: downloadUrl,
        }));
        setProgresspercent(0);
      }
    );
  }, [image2Upload, details, setDetails]);

  return (
    <>
      <img
        style={{ width: 100, height: 100, objectFit: "cover" }}
        src={details.imageUrl || ""}
        alt="Imagem do profissional"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => imageUploadRef.current?.click()}
      >
        <input
          type="file"
          hidden
          ref={imageUploadRef}
          accept="image/*"
          onChange={(e) => setImage2Upload(e.target.files?.[0] || null)}
        />
        {image2Upload ? (
          <span>
            {image2Upload.name}
            {progresspercent > 0 && ` (${progresspercent}%)`}
          </span>
        ) : (
          "Submeter imagem de perfil"
        )}
      </Button>
    </>
  );
};
