import { Appointment } from "../models/Appointment";
import { ProviderInformation } from "../models/ProviderInformation";

export const generateEmailRequest = (
  selectedProvider: ProviderInformation,
  newAppointment: Appointment
) => {
  // TODO: Convert this to global
  const appName = `Zip Saúde`;

  return `
        <h2>Olá, ${selectedProvider?.name}!</h2>
        <p>Você tem uma nova solicitação de agendamento.</p>

        <p>O paciente sugeriu os seguintes horários:</p>
        <ul>
        ${newAppointment.option1 ? `<li>${newAppointment.option1}</li>` : ""}
        ${newAppointment.option2 ? `<li>${newAppointment.option2}</li>` : ""}
        ${newAppointment.option3 ? `<li>${newAppointment.option3}</li>` : ""}
        </ul>

        <p>Acesse o <a href="${
          window.location.origin
        }/provider/">painel de agendamentos</a> para confirmar ou recusar a solicitação.</p>

        <p>Atenciosamente,</p>
        <p>Equipe do ${appName}</p>
    `;
};
