import { useQuery } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { Clinic } from "../../models/Clinic";

export const useClinicDetailsQuery = (clinicId: string | undefined | null) => {
  return useQuery<Clinic>({
    queryKey: ["clinics", clinicId],
    queryFn: async () => {
      if (!clinicId) {
        throw new Error("Clinic ID is required");
      }

      const docRef = doc(fireStoreDb, "clinics", clinicId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error("Clinic not found");
      }

      const clinicData = docSnap.data() as Clinic;

      return {
        ...clinicData,
        id: docSnap.id,
      };
    },
    enabled: Boolean(clinicId),
  });
};
