import { useQuery } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc as docRef,
  where,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ProviderInformation } from "../../models/ProviderInformation";
import { Clinic } from "../../models/Clinic";

const fetchProviders = async (
  relatedClinicId?: string,
  examId?: string
): Promise<ProviderInformation[]> => {
  let providerQuery = query(collection(fireStoreDb, "providers"));

  if (relatedClinicId) {
    providerQuery = query(
      collection(fireStoreDb, "providers"),
      where("relatedClinicId", "==", relatedClinicId)
    );
  }

  if (examId) {
    providerQuery = query(
      providerQuery,
      where("exams", "array-contains", examId)
    );
  }

  const querySnapshot = await getDocs(providerQuery);

  const providers = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      let relatedClinic: Clinic = {} as Clinic;
      if (doc.data().relatedClinicId) {
        const clinicDoc = await getDoc(
          docRef(fireStoreDb, "clinics", doc.data().relatedClinicId as string)
        );
        relatedClinic = clinicDoc.data() as Clinic;
      }
      return {
        ...doc.data(),
        id: doc.id,
        relatedClinic,
      } as ProviderInformation;
    })
  );

  // Sort providers by `averageRating`, placing those without a rating at the bottom
  return providers.sort((a, b) => {
    const ratingA = a.averageRating ?? -1; // Place providers without a rating last
    const ratingB = b.averageRating ?? -1;
    return ratingB - ratingA; // Sort descending by rating
  });
};

export const useProviderQuery = (relatedClinicId?: string, examId?: string) => {
  return useQuery<ProviderInformation[], Error>({
    queryKey: ["providers", "list", relatedClinicId, examId],
    queryFn: () => fetchProviders(relatedClinicId, examId),
    enabled: !!relatedClinicId || !!examId || (!relatedClinicId && !examId), // Enable query when conditions are met
  });
};
