import { Input } from "@nextui-org/react";
import { useMemo, useState } from "react";
import { PasswordToggle } from "../../Atoms/PasswordToggle";

interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
}

interface PasswordInputProps {
  password: string;
  setPassword: (value: string) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  label?: string;
}

export const EmailInput: React.FC<EmailInputProps> = ({ email, setEmail }) => {
  const validateEmail = (value: string) =>
    value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

  const isInvalid = useMemo(() => {
    return email !== "" && !validateEmail(email);
  }, [email]);

  return (
    <Input
      size="lg"
      type="email"
      label="E-mail"
      variant="bordered"
      isInvalid={isInvalid}
      color={isInvalid ? "danger" : "default"}
      errorMessage="Digite um e-mail válido"
      value={email}
      onValueChange={setEmail}
      isRequired
    />
  );
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
  isInvalid,
  errorMessage,
  label = "Senha",
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Input
      size="lg"
      type={isVisible ? "text" : "password"}
      endContent={
        <PasswordToggle
          isVisible={isVisible}
          onClick={() => setIsVisible(!isVisible)}
        />
      }
      label={label}
      variant="bordered"
      value={password}
      onValueChange={setPassword}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      isRequired
    />
  );
};
