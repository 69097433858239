import { dayjs } from "../libs/dayjsConfig";
import { Schedule } from "../models/Schedule";

// Format to use when storing dates in the database (always in UTC)
const DB_FORMAT = "YYYY-MM-DDTHH:mm:00.000Z";

// Format to use for display in the Brazilian format
const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";

export const DateUtils = {
  // Convert a date to the format used in the database (always in UTC)
  toDBFormat: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ): string | null => {
    if (!date) return null;
    const d = dayjs(date);
    return timezone
      ? d.tz(timezone).utc().format(DB_FORMAT)
      : d.utc().format(DB_FORMAT);
  },

  // Convert a query date to the format used in the database, using a threshold
  queryToDBFormat: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ): string | null => {
    if (!date) return null;
    const d = dayjs(date);
    return timezone
      ? d.tz(timezone).utc().endOf("day").format(DB_FORMAT)
      : d.utc().endOf("day").format(DB_FORMAT);
  },

  // Parse a date from the database format (UTC) to a specific timezone
  fromDBFormat: (
    dateString: string | null | undefined,
    timezone?: string
  ): dayjs.Dayjs | null => {
    if (!dateString) return null;
    const utcDate = dayjs.utc(dateString);
    return timezone ? utcDate.tz(timezone) : utcDate;
  },

  // Format a date for display in the specified timezone
  toDisplayFormat: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ): string => {
    if (!date) return "";
    const d = dayjs(date);
    return timezone
      ? d.tz(timezone).format(DISPLAY_FORMAT)
      : d.format(DISPLAY_FORMAT);
  },

  // Parse a date from the display format in a specific timezone
  fromDisplayFormat: (
    dateString: string,
    timezone?: string
  ): dayjs.Dayjs | null => {
    const parsedDate = timezone
      ? dayjs.tz(dateString, DISPLAY_FORMAT, timezone)
      : dayjs(dateString, DISPLAY_FORMAT);
    return parsedDate.isValid() ? parsedDate : null;
  },

  // Get separate date and time strings for display in a specific timezone
  getFormattedDateAndTime: (
    date: dayjs.Dayjs | Date | string | null,
    timezone?: string
  ) => {
    if (!date) return { formattedDate: "", formattedTime: "" };
    const parsedDate = timezone ? dayjs(date).tz(timezone) : dayjs(date);
    return {
      formattedDate: parsedDate.format("DD/MM/YYYY"),
      formattedTime: parsedDate.format("HH:mm"),
    };
  },

  // Compare two dates (useful for sorting)
  compareDates: (
    a: dayjs.Dayjs | Date | string | null,
    b: dayjs.Dayjs | Date | string | null
  ): number => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    return dayjs(a).valueOf() - dayjs(b).valueOf();
  },

  // Check if a date is valid
  isValidDate: (date: Date | string | dayjs.Dayjs): boolean => {
    return dayjs(date).isValid();
  },

  // Get current date and time in a specific timezone
  now: (timezone?: string): dayjs.Dayjs => {
    return timezone ? dayjs().tz(timezone) : dayjs();
  },

  // Convert a date from one timezone to another
  convertTimezone: (
    date: dayjs.Dayjs | Date | string,
    fromTz: string,
    toTz: string
  ): dayjs.Dayjs => {
    return dayjs(date).tz(fromTz).tz(toTz);
  },

  calculateEndTime: (
    startTime: string,
    appointmentType: "exam" | "appointment",
    examId: string | undefined,
    schedule: Schedule,
    timezone: string
  ): string => {
    const start = dayjs(startTime).tz(timezone);
    let duration: number;

    if (appointmentType === "exam" && examId && schedule.exams?.[examId]) {
      duration = schedule.exams[examId].duration || 0;
    } else if (
      appointmentType === "appointment" &&
      examId &&
      schedule.services?.[examId]
    ) {
      duration = schedule.services[examId].duration || 0;
    } else {
      duration = parseInt(schedule.defaultDuration || "60", 10);
    }

    return start.add(duration, "minute").format();
  },

  isCancelable: (
    appointmentDate: string | null | undefined,
    timezone?: string
  ): boolean => {
    if (!appointmentDate) return false;
    const now = DateUtils.now(timezone);
    const appointment = DateUtils.fromDBFormat(appointmentDate, timezone);
    if (!appointment) return false;
    return appointment.diff(now, "hour") > 24;
  },
};
