import { Button, ButtonProps } from "@nextui-org/react";

interface ActionButtonProps extends ButtonProps {
  label: React.ReactNode | string;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  label,
  ...props
}) => (
  <Button {...props} className={`w-full font-medium ${props.className || ""}`}>
    {label}
  </Button>
);
