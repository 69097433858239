import styled from "styled-components"

export const Container = styled.div`
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`