import React, { ReactNode } from "react";
import { Card, CardBody, CardFooter, Button } from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion";
import { promptAnimationVariants } from "../../../utils/promptAnimationVariants";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

interface PromptProps {
  show: boolean;
  onDismiss: () => void;
  icon: ReactNode;
  message: ReactNode;
  confirmButton?: ReactNode;
  cancelText: string;
}

export const Prompt: React.FC<PromptProps> = ({
  show,
  onDismiss,
  icon,
  message,
  confirmButton,
  cancelText,
}) => {
  const isMobile = useMediaQuery("(max-width: 767px)");

  const containerStyles = isMobile
    ? "fixed top-0 left-0 right-0 px-2 pt-2 z-50"
    : "fixed bottom-4 right-4 z-50";

  const variants = isMobile
    ? promptAnimationVariants.mobile
    : promptAnimationVariants.desktop;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={containerStyles}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
          variants={variants}
        >
          <Card
            isBlurred
            className={`${
              isMobile ? "w-full" : "max-w-md"
            } border-none bg-background/60 dark:bg-default-100/50`}
            shadow="sm"
          >
            <CardBody className="flex flex-row items-center gap-4">
              {icon}
              {message}
            </CardBody>
            <CardFooter className="justify-end gap-2 p-4 pt-0">
              <Button
                variant="bordered"
                color="default"
                onPress={onDismiss}
                size="sm"
              >
                {cancelText}
              </Button>
              {confirmButton}
            </CardFooter>
          </Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
