export const promptAnimationVariants = {
  mobile: {
    initial: { opacity: 0, y: -75 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -75 },
  },
  desktop: {
    initial: { opacity: 0, x: 75 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 75 },
  },
};
