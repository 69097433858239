import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useCancelAppointment } from "../../../hooks/queries/useCancelAppointment";
import { useState } from "react";

export const CancelAppointmentModal = ({
  isOpen,
  description,
  appointmentId,
  role,
  onOpenChange,
}: {
  isOpen: boolean;
  description: string;
  appointmentId: string;
  role: "customer" | "provider";
  onOpenChange: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelAppointmentMutation = useCancelAppointment();

  const cancelAppointment = async (onClose: () => void) => {
    setIsLoading(true);
    cancelAppointmentMutation.mutate(
      { id: appointmentId, role },
      {
        onSuccess: () => {
          setIsLoading(false);
          onClose();
        },
        onError: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <Modal
      backdrop="blur"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      classNames={{
        backdrop: "bg-black/75",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className=" text-danger-900 max-md:text-center max-md:w-full">
              Você tem certeza que quer cancelar esse agendamento?
            </ModalHeader>
            <ModalBody>
              <p className="max-md:text-center">{description}</p>
            </ModalBody>
            <ModalFooter className="gap-4">
              <Button
                color="primary"
                variant="light"
                onPress={onClose}
                className="font-medium"
              >
                Cancelar
              </Button>
              <Button
                color="danger"
                variant="solid"
                onPress={() => cancelAppointment(onClose)}
                isLoading={isLoading}
              >
                Confirmar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
