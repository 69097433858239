import React, { useEffect, useState } from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RangeCalendar,
  CheckboxGroup,
  Checkbox,
} from "@nextui-org/react";
import { Filter } from "lucide-react";
import { CalendarDate } from "@internationalized/date";
import { DateRangeDisplay } from "../../Atoms/DateRangeDisplay";
import DatePresetButtonGroup from "../../Atoms/DatePresetButtonGroup";

interface AppointmentFilterProps {
  dateRange: { start: CalendarDate; end: CalendarDate };
  onDateRangeChange: (range: {
    start: CalendarDate;
    end: CalendarDate;
  }) => void;
  onReset: () => void;
  appointmentTypes: string[];
  onAppointmentTypesChange: (types: string[]) => void;
  defaultDateRange: { start: CalendarDate; end: CalendarDate };
  defaultAppointmentTypes: string[];
}

const APPOINTMENT_TYPE_OPTIONS = ["Consultas", "Exames"];

export const AppointmentFilter: React.FC<AppointmentFilterProps> = ({
  dateRange,
  onDateRangeChange,
  onReset,
  appointmentTypes,
  onAppointmentTypesChange,
  defaultDateRange,
  defaultAppointmentTypes,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [localAppointmentTypes, setLocalAppointmentTypes] =
    useState(appointmentTypes);
  const [localDateRange, setLocalDateRange] = useState(dateRange);
  const [isCustomFilterActive, setIsCustomFilterActive] = useState(false);

  const handleDatePresetChange = (newDateRange: {
    start: CalendarDate;
    end: CalendarDate;
  }) => {
    setLocalDateRange(newDateRange);
  };

  const handleConfirmFilter = () => {
    onDateRangeChange(localDateRange);
    onAppointmentTypesChange(localAppointmentTypes);
    setIsFilterOpen(false);
  };

  const handleAppointmentTypesChange = (value: string[]) => {
    if (value.length === 0) {
      // If trying to uncheck the last option, toggle to the other option
      setLocalAppointmentTypes([
        APPOINTMENT_TYPE_OPTIONS.find(
          (type) => type !== localAppointmentTypes[0]
        )!,
      ]);
    } else {
      // Sort the selected types to maintain consistent order
      setLocalAppointmentTypes(
        APPOINTMENT_TYPE_OPTIONS.filter((type) => value.includes(type))
      );
    }
  };

  const handleReset = () => {
    setLocalDateRange(defaultDateRange);
    setLocalAppointmentTypes(defaultAppointmentTypes);
    onReset();
    setIsFilterOpen(false);
  };

  const handleLocalDateRangeChange = (newRange: {
    start: CalendarDate;
    end: CalendarDate;
  }) => {
    setLocalDateRange(newRange);
  };

  useEffect(() => {
    const isDateRangeChanged =
      dateRange.start.compare(defaultDateRange.start) !== 0 ||
      dateRange.end.compare(defaultDateRange.end) !== 0;
    const isAppointmentTypesChanged =
      JSON.stringify(appointmentTypes) !==
      JSON.stringify(defaultAppointmentTypes);

    setIsCustomFilterActive(isDateRangeChanged || isAppointmentTypesChanged);
  }, [dateRange, appointmentTypes, defaultDateRange, defaultAppointmentTypes]);

  return (
    <Popover
      placement="bottom"
      isOpen={isFilterOpen}
      onOpenChange={(open) => setIsFilterOpen(open)}
      classNames={{ content: "p-0" }}
    >
      <PopoverTrigger>
        <Button
          variant="flat"
          color={isCustomFilterActive ? "success" : "warning"}
          startContent={<Filter className={`max-md:w-5 w-4 `} />}
          className={`h-11 min-w-fit w-fit ${
            !isCustomFilterActive && "bg-warning-50 text-warning"
          }`}
        >
          <DateRangeDisplay start={dateRange.start} end={dateRange.end} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-2 rounded-large items-start">
        <RangeCalendar
          aria-label="Calendário de filtro"
          color="warning"
          value={localDateRange}
          onChange={handleLocalDateRangeChange}
          topContent={
            <DatePresetButtonGroup onSelectPreset={handleDatePresetChange} />
          }
          className="rounded-medium"
          classNames={{
            cellButton:
              "data-[selected=true]:data-[range-selection=true]:before:bg-warning-100/50",
          }}
        />
        <div className="py-4">
          <CheckboxGroup
            label="Tipos de Agendamento"
            orientation="horizontal"
            color="warning"
            value={localAppointmentTypes}
            onValueChange={handleAppointmentTypesChange}
          >
            {APPOINTMENT_TYPE_OPTIONS.map((type) => (
              <Checkbox key={type} value={type}>
                {type}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </div>
        <div className="flex gap-2 w-full justify-end">
          <Button
            size="sm"
            radius="md"
            color="warning"
            variant="bordered"
            className="w-full"
            onPress={handleReset}
          >
            Redefinir
          </Button>
          <Button
            size="sm"
            radius="md"
            color="warning"
            variant="solid"
            className="w-full font-medium"
            onPress={handleConfirmFilter}
          >
            Confirmar
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
