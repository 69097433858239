import { Avatar, Button, Chip } from "@nextui-org/react";
import { CircleDollarSign, MapPin, Phone, Star } from "lucide-react";
import { Clinic } from "../../../models/Clinic";

export const ClinicCard = ({
  clinic,
  onCardClick,
  medicalService,
}: {
  clinic: Clinic;
  medicalService: string;
  onCardClick: () => void;
}) => {
  return (
    <div
      className="flex flex-col gap-4 bg-default-200 rounded-large border border-default-400 p-2 min-w-80 max-w-xl max-md:w-full cursor-pointer"
      onClick={onCardClick}
    >
      <div className="flex justify-between flex-wrap gap-2">
        <div className="flex gap-2">
          <Avatar name={clinic.name} className="h-16 w-16" />
          <div className="flex flex-col mt-1">
            <span className="text-sm font-medium text-default-900">
              {clinic.name}
            </span>
            <span className="text-sm font-medium text-default-400">
              {clinic.address}
            </span>
          </div>
        </div>
        <Chip
          variant="flat"
          color="warning"
          startContent={<Star className="w-4 ml-1 stroke-[3]" />}
          className="flex-grow"
        >
          Clínica
        </Chip>
      </div>

      <div className="flex flex-col gap-1 mx-1">
        <span className="flex gap-1">
          <MapPin className="w-4" />
          {clinic.address}
        </span>
        <span className="flex gap-1">
          <Phone className="w-4" />
          {clinic.phoneNumber}
        </span>
        <span className="flex gap-1">
          <CircleDollarSign className="w-4" />
          {clinic.schedules?.exams?.exams?.[medicalService].price || ""}
        </span>
      </div>

      <Button
        type="button"
        radius="md"
        variant="flat"
        color="primary"
        className="font-semibold"
        onPress={onCardClick}
      >
        Ver detalhes
      </Button>
    </div>
  );
};
