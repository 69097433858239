import { useQuery } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc as docRef,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { Clinic } from "../../models/Clinic";

const fetchClinics = async (): Promise<Clinic[]> => {
  const clinicQuery = query(collection(fireStoreDb, "clinics"));

  const querySnapshot = await getDocs(clinicQuery);

  const clinics = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      let relatedClinic: Clinic = {
        timezone: "America/Sao_Paulo",
      };
      if (doc.data().relatedClinicId) {
        const clinicDoc = await getDoc(
          docRef(fireStoreDb, "clinics", doc.data().relatedClinicId as string)
        );
        relatedClinic = clinicDoc.data() as Clinic;
      }
      return {
        ...doc.data(),
        id: doc.id,
        relatedClinic,
        timezone: "America/Sao_Paulo",
      } as Clinic;
    })
  );

  return clinics;
};

export const useClinicQuery = () => {
  return useQuery<Clinic[], Error>({
    queryKey: ["clinics", "list"],
    queryFn: () => fetchClinics(),
  });
};
