import { WhatsApp } from "@mui/icons-material";
import { AtSign } from "lucide-react";
import { ActionButton } from "../../Atoms/ActionButton";

interface AppointmentCommunicationButtonsProps {
  onWhatsAppClick: () => void;
  onEmailClick: () => void;
  isWhatsAppDisabled: boolean;
  isEmailDisabled: boolean;
}

export const AppointmentCommunicationButtons: React.FC<
  AppointmentCommunicationButtonsProps
> = ({
  onWhatsAppClick,
  onEmailClick,
  isWhatsAppDisabled,
  isEmailDisabled,
}) => (
  <div className="flex gap-1 justify-center items-center w-full">
    <ActionButton
      variant="solid"
      color="secondary"
      startContent={<WhatsApp />}
      isDisabled={isWhatsAppDisabled}
      onPress={onWhatsAppClick}
      label={<span className="max-md:hidden">Enviar Mensagem</span>}
    />
    <ActionButton
      variant="solid"
      color="warning"
      startContent={<AtSign />}
      isDisabled={isEmailDisabled}
      onPress={onEmailClick}
      label={<span className="max-md:hidden">Enviar E-mail</span>}
    />
  </div>
);
