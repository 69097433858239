import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useMyAppointments } from "../../../hooks/queries/useMyAppointments";
import { useProvidersQuery } from "../../../hooks/queries/useProvidersByIdQuery";
import { useAuth } from "../../../contexts/auth.context";
import { AppointmentStatus } from "../../../models/Appointment";
import { useMultipleUsersQuery } from "../../../hooks/queries/useMultipleUsersQuery";
import { CalendarDate, today } from "@internationalized/date";
import { useInView } from "react-intersection-observer";
import { MyAppointmentsTemplate } from "../../Templates/MyAppointmentsTemplate";
import { useSearchParams } from "react-router-dom";
import {
  statusToTabMap,
  tabToStatusMap,
} from "../../../utils/statusTabMapping";
import { useClinicsQuery } from "../../../hooks/queries/useClinicsQuery";

const DEFAULT_DATE_RANGE = {
  start: today("America/Sao_Paulo").subtract({ days: 7 }),
  end: today("America/Sao_Paulo").add({ days: 7 }),
};

const DEFAULT_APPOINTMENT_TYPES = ["Consultas", "Exames"];

export const MyAppointments: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStatus, setSelectedStatus] = useState<AppointmentStatus>(
    () => {
      const tabParam = searchParams.get("tab");
      return (
        tabToStatusMap[tabParam || ""] || AppointmentStatus.WAITING_PROVIDER
      );
    }
  );
  const [dateRange, setDateRange] = useState<{
    start: CalendarDate;
    end: CalendarDate;
  }>({
    start: today("America/Sao_Paulo").subtract({ days: 7 }),
    end: today("America/Sao_Paulo").add({ days: 7 }),
  });
  const [appointmentTypes, setAppointmentTypes] = useState<string[]>([
    "Consultas",
    "Exames",
  ]);
  const { currentUser } = useAuth();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [isCustomFilterActive, setIsCustomFilterActive] = useState(false);

  const appointmentsQuery = useMyAppointments(
    selectedStatus,
    dateRange,
    appointmentTypes
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = appointmentsQuery;

  const appointments = useMemo(() => {
    return data?.pages.flatMap((page) => page) ?? [];
  }, [data]);

  const providerIds = useMemo(() => {
    return [...new Set(appointments.map((a) => a.providerId))];
  }, [appointments]);
  const clinicIds = useMemo(() => {
    return [...new Set(appointments.map((a) => a.clinicId))];
  }, [appointments]);

  const userIds = useMemo(() => {
    return currentUser.role === "provider"
      ? [...new Set(appointments.map((a) => a.userId))]
      : [];
  }, [appointments, currentUser.role]);

  const { data: providers, isLoading: isLoadingProviders } = useProvidersQuery(
    providerIds as string[]
  );
  const { data: clinics } = useClinicsQuery(clinicIds as string[]);
  const { data: users } = useMultipleUsersQuery(userIds as string[]);

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  useEffect(() => {
    const tabParam = searchParams.get("tab");
    const newStatus =
      tabToStatusMap[tabParam || ""] || AppointmentStatus.WAITING_PROVIDER;
    setSelectedStatus(newStatus);
  }, [searchParams]);

  useEffect(() => {
    const isDateRangeChanged =
      dateRange.start.compare(DEFAULT_DATE_RANGE.start) !== 0 ||
      dateRange.end.compare(DEFAULT_DATE_RANGE.end) !== 0;
    const isAppointmentTypesChanged =
      JSON.stringify(appointmentTypes) !==
      JSON.stringify(DEFAULT_APPOINTMENT_TYPES);

    setIsCustomFilterActive(isDateRangeChanged || isAppointmentTypesChanged);
  }, [dateRange, appointmentTypes]);

  const handleStatusChange = (status: AppointmentStatus) => {
    setSelectedStatus(status);
    setSearchParams({ tab: statusToTabMap[status] });
  };

  const handleDateRangeChange = (range: {
    start: CalendarDate;
    end: CalendarDate;
  }) => {
    setDateRange(range);
  };

  const handleResetDateRange = () => {
    setDateRange({
      start: today("America/Sao_Paulo").subtract({ days: 7 }),
      end: today("America/Sao_Paulo").add({ days: 7 }),
    });
  };

  const handleAppointmentTypesChange = (types: string[]) => {
    setAppointmentTypes(types);
  };

  if (error) {
    return <div>Error loading appointments: {error.message}</div>;
  }

  return (
    <MyAppointmentsTemplate
      selectedStatus={selectedStatus}
      onStatusChange={handleStatusChange}
      dateRange={dateRange}
      onDateRangeChange={handleDateRangeChange}
      onResetDateRange={handleResetDateRange}
      appointmentTypes={appointmentTypes}
      onAppointmentTypesChange={handleAppointmentTypesChange}
      appointments={appointments}
      providers={providers || []}
      clinics={clinics || []}
      currentUser={currentUser}
      users={users}
      isLoading={isLoading}
      isLoadingProviders={isLoadingProviders}
      isFetchingNextPage={isFetchingNextPage}
      lastItemRef={ref}
      handleSwipe={handleStatusChange}
      isCustomFilterActive={isCustomFilterActive}
    />
  );
};
