import { Eye, EyeOff } from "lucide-react";

interface PasswordToggleProps {
  isVisible: boolean;
  onClick: () => void;
}

export const PasswordToggle: React.FC<PasswordToggleProps> = ({
  isVisible,
  onClick,
}) => (
  <button className="rounded-full" type="button" onClick={onClick}>
    {isVisible ? (
      <Eye className="text-default-900" />
    ) : (
      <EyeOff className="text-default-900" />
    )}
  </button>
);
