import { Button, Card, CardBody, useDisclosure } from "@nextui-org/react";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { UserInfo } from "../../Molecules/UserInfo";
import { AppointmentInfo } from "../../Atoms/AppointmentInfo";
import {
  AtSign,
  BriefcaseMedical,
  HandCoins,
  HeartHandshake,
  Info,
  Phone,
} from "lucide-react";
import { getFormattedAppointmentOption } from "../../../utils/getFormattedAppointmentOption";
import { UserInformation } from "../../../models/UserInformation";
import { ConfirmAppointmentModal } from "../ConfirmAppointmetModal";
import { useState } from "react";
import { DateUtils } from "../../../utils/dateUtils";

interface ProviderDetails {
  appointment: Appointment;
  userInfo: UserInformation | undefined;
  isUserLoading: boolean;
  timezone: string;
}

export const ProviderDetails: React.FC<ProviderDetails> = ({
  appointment,
  userInfo,
  isUserLoading,
  timezone,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { formatDate } = getFormattedAppointmentOption();

  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onOpenChange: onConfirmModalOpenChange,
  } = useDisclosure();

  const renderAppointmentOption = (
    option: string | null | undefined,
    index: number
  ) => {
    if (!option) return null;

    const { formattedDate, formattedTime } = formatDate(option, timezone);

    return (
      <div
        key={index}
        className="bg-warning-100/50 rounded-xl p-2 max-md:w-full"
      >
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="flex flex-col items-center">
            <h2 className={`text-warning-900 text-2xl font-semibold`}>
              {formattedDate}
            </h2>
            <span
              className={`text-warning-900 text-sm leading-none font-semibold`}
            >
              {formattedTime}
            </span>
          </div>
          <Button
            variant="bordered"
            color="warning"
            className="w-full"
            radius="md"
            onPress={() => {
              setSelectedOption(option as string);
              onConfirmModalOpen();
            }}
          >
            Selecionar
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card radius="md" shadow="none">
        <CardBody className="gap-4 p-0">
          <UserInfo
            userInfo={userInfo}
            description="Usuário(a) Zip"
            isLoading={isUserLoading}
          />
          <div className="flex flex-col gap-1 mx-0.5">
            <AppointmentInfo
              icon={<Phone className="w-4" />}
              text={userInfo?.phoneNumber as string}
            />
            <AppointmentInfo
              icon={<AtSign className="w-4" />}
              text={userInfo?.email as string}
            />
            {appointment.serviceId && (
              <AppointmentInfo
                icon={<BriefcaseMedical className="w-4" />}
                text={appointment.serviceId as string}
                className="flex gap-1"
              />
            )}
            {appointment.paymentMethod && (
              <AppointmentInfo
                icon={<HandCoins className="w-4" />}
                text={appointment.paymentMethod as string}
                className="flex gap-1"
              />
            )}
            {appointment.healthInsurance && (
              <AppointmentInfo
                icon={<HeartHandshake className="w-4" />}
                text={appointment.healthInsurance as string}
                className="flex gap-1 text-success-700"
              />
            )}
          </div>
          {appointment.reason && (
            <div className="flex flex-col gap-2 p-1 bg-default-100 rounded-small w-full">
              <span className="flex gap-1 text-default-600 font-semibold leading-none">
                <Info className="w-4 h-4 stroke-[3] stroke-default-900" />O
                paciente relatou:
              </span>
              <span className="text-default-900 max-w-96 ml-0.5">
                {appointment.reason}
              </span>
            </div>
          )}
        </CardBody>
      </Card>

      {appointment.status === AppointmentStatus.WAITING_PROVIDER && (
        <div className="flex flex-col gap-2">
          <h5>Sugestões:</h5>
          <div className="flex gap-2 flex-wrap">
            {renderAppointmentOption(appointment.option1, 1)}
            {renderAppointmentOption(appointment.option2, 2)}
            {renderAppointmentOption(appointment.option3, 3)}
          </div>
        </div>
      )}

      <ConfirmAppointmentModal
        isOpen={isConfirmModalOpen}
        onOpenChange={onConfirmModalOpenChange}
        appointmentId={appointment.id as string}
        fullDate={selectedOption as string}
        date={
          selectedOption
            ? DateUtils.toDisplayFormat(selectedOption).split(" ")[0]
            : ""
        }
        time={
          selectedOption
            ? DateUtils.toDisplayFormat(selectedOption).split(" ")[1]
            : ""
        }
      />
    </>
  );
};
