import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging } from "firebase/messaging";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
} from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const getEnv = (key: string) => {
  if (typeof process !== "undefined" && process.env) {
    return process.env[key];
  }
  // @ts-ignore
  if (typeof import.meta !== "undefined" && import.meta.env) {
    // @ts-ignore
    return import.meta.env[key];
  }
  return undefined;
};

const firebaseConfig = {
  apiKey: getEnv("VITE_FIREBASE_API_KEY"),
  authDomain: getEnv("VITE_FIREBASE_AUTH_DOMAIN"),
  projectId: getEnv("VITE_FIREBASE_PROJECT_ID"),
  storageBucket: getEnv("VITE_FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getEnv("VITE_FIREBASE_MESSAGING_SENDER_ID"),
  appId: getEnv("VITE_FIREBASE_APP_ID"),
  measurementId: getEnv("VITE_FIREBASE_MEASUREMENT_ID"),
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const fireStoreDb = getFirestore(app);
export const performance = getPerformance(app);
export const messaging = getMessaging(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfCvxkqAAAAABkGiS6btliXDA1jj54jpOKSpfis"),
  isTokenAutoRefreshEnabled: true,
});

export const googleProvider = new GoogleAuthProvider();

if (getEnv("VITE_USE_FIREBASE_EMULATOR") === "true") {
  console.log("Using Firebase emulators...");
  connectFirestoreEmulator(fireStoreDb, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199);
}
