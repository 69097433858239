import { Avatar as NextUIAvatar } from "@nextui-org/react";

export const Avatar = ({
  name,
  isBordered,
  src,
  className,
  ...props
}: {
  name: string;
  src: string;
  isBordered: boolean;
  className?: string;
}) => (
  <NextUIAvatar
    isBordered={isBordered}
    src={src}
    name={name?.slice(0, 2).toUpperCase() || ""}
    className={className}
    {...props}
  />
);
