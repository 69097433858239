import { useCallback } from "react";
import { produce } from "immer";
import { Dayjs } from "dayjs";
import { ProviderInformation } from "../models/ProviderInformation";
import { Schedule } from "../models/Schedule";
import { DateUtils } from "../utils/dateUtils";
import { Clinic } from "../models/Clinic";

export const useScheduleManager = (
  setDetails: React.Dispatch<React.SetStateAction<ProviderInformation | Clinic>>
) => {
  const handleTimeChange = useCallback(
    (
      scheduleType: "appointments" | "exams",
      field: keyof Schedule,
      value: Dayjs | string | null
    ) => {
      setDetails(
        produce((draft: any) => {
          const utcTime = value
            ? DateUtils.toDBFormat(value, draft.timezone)
            : null;

          if (!draft.schedules[scheduleType]) {
            draft.schedules[scheduleType] = {};
          }
          draft.schedules[scheduleType][field] = utcTime;
        })
      );
    },
    [setDetails]
  );

  return { handleTimeChange };
};
