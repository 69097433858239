import React from "react";
import { motion } from "framer-motion";

interface ActionSectionProps {
  children: React.ReactNode;
  title: string;
}

export const ActionSection: React.FC<ActionSectionProps> = ({
  children,
  title,
}) => {
  return (
    <motion.div
      layout
      className="flex flex-col justify-center items-start bg-white rounded-large gap-2 p-2 w-full"
    >
      <motion.div layout className="w-full bg-default-100 rounded-medium p-2">
        <motion.h2 layout className="text-default-700 font-semibold text-sm">
          {title}
        </motion.h2>
      </motion.div>
      {children}
    </motion.div>
  );
};
