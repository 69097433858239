import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { UserInformation } from "../../models/UserInformation";
import { State } from "../../models/State";
import { City } from "../../models/City";
import { useAuth } from "../../contexts/auth.context";
import {
  getCurrentLocation,
  harmonizeLocation,
  reverseGeocode,
} from "../../utils/locationUtils";
import { useIBGEStatesQuery } from "./useIBGEStates";

export const useUserLocation = () => {
  const queryClient = useQueryClient();
  const { currentUser, updateUser } = useAuth();
  const { data: states, refetch: refetchStates } = useIBGEStatesQuery();

  const updateUserLocation = async (harmonizedLocation: {
    state: State | undefined;
    city: City | undefined;
  }): Promise<UserInformation["location"]> => {
    if (!currentUser) throw new Error("User not authenticated");
    if (!harmonizedLocation.state || !harmonizedLocation.city)
      throw new Error("Invalid location data");

    const userDocRef = doc(fireStoreDb, "users", currentUser.uid);

    const newLocation: UserInformation["location"] = {
      state: {
        id: harmonizedLocation.state.id,
        sigla: harmonizedLocation.state.sigla,
        nome: harmonizedLocation.state.nome,
      },
      city: {
        id: harmonizedLocation.city.id,
        nome: harmonizedLocation.city.nome,
      },
    };

    updateUser({ location: newLocation });
    await updateDoc(userDocRef, { location: newLocation });
    return newLocation;
  };

  const autoLocationMutation = useMutation({
    mutationFn: async () => {
      const position = await getCurrentLocation();
      const { latitude, longitude } = position.coords;
      const geocodedLocation = await reverseGeocode(latitude, longitude);

      const allStates = states || (await refetchStates()).data;
      if (!allStates) throw new Error("Failed to fetch states");

      const harmonizedLocation = await harmonizeLocation(
        geocodedLocation,
        allStates,
        async (state) => {
          const cities = await queryClient.fetchQuery({
            queryKey: ["IBGECities", state.id],
            queryFn: async () => {
              const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.id}/municipios`
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json() as Promise<City[]>;
            },
          });
          return cities || [];
        }
      );

      return updateUserLocation(harmonizedLocation);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["userInformation"],
      });
      queryClient.setQueryData<UserInformation | undefined>(
        ["userInformation"],
        (oldData) => (oldData ? { ...oldData, location: data } : oldData)
      );
    },
  });

  const manualLocationMutation = useMutation({
    mutationFn: async (location: { state: State; city: City }) => {
      return updateUserLocation(location);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["userInformation"],
      });
      queryClient.setQueryData<UserInformation | undefined>(
        ["userInformation"],
        (oldData) => (oldData ? { ...oldData, location: data } : oldData)
      );
    },
    onError: (error) => {
      console.error("Error updating location:", error);
    },
  });

  return {
    autoLocationMutation,
    manualLocationMutation,
    isManualLocationMutationLoading: manualLocationMutation.isPending,
  };
};
