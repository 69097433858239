import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { MedicalService } from "../../models/MedicalService";
import { fireStoreDb } from "../../services/firebase/firebase";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../libs/queryClient";

// Function to fetch all medical services
const fetchMedicalServices = async (): Promise<MedicalService[]> => {
  const querySnapshot = await getDocs(
    collection(fireStoreDb, "medical_services")
  );
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    type: doc.data().type,
  }));
};

// Function to fetch medical services by type
const fetchMedicalServicesByType = async (
  type: "specialty" | "exam" | "service"
): Promise<MedicalService[]> => {
  const q = query(
    collection(fireStoreDb, "medical_services"),
    where("type", "==", type)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    type: doc.data().type,
  }));
};

// Hook to fetch all medical services
export const useMedicalServicesQuery = () => {
  return useQuery<MedicalService[], Error>({
    queryKey: ["medicalServices"],
    queryFn: fetchMedicalServices,
  });
};

// Hook to fetch specialties
export const useSpecialtiesQuery = () => {
  return useQuery<MedicalService[], Error>({
    queryKey: ["specialties"],
    queryFn: () => fetchMedicalServicesByType("specialty"),
  });
};

// Hook to fetch services
export const useServicesQuery = () => {
  return useQuery<MedicalService[], Error>({
    queryKey: ["services"],
    queryFn: () => fetchMedicalServicesByType("service"),
  });
};

// Hook to fetch exams
export const useExamsQuery = () => {
  return useQuery<MedicalService[], Error>({
    queryKey: ["exams"],
    queryFn: () => fetchMedicalServicesByType("exam"),
  });
};

// Hook to add services
export const useAddService = () => {
  return useMutation({
    mutationFn: async (newService: string) => {
      const docRef = doc(
        collection(fireStoreDb, "medical_services"),
        newService
      );
      await setDoc(docRef, { id: newService, type: "service" });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["medical_services", variables],
      });
    },
    onError: (error) => {
      console.error(`Mutation error:`, error);
    },
  });
};

// Hook to add exams
export const useAddExam = () => {
  return useMutation({
    mutationFn: async (newExam: string) => {
      const docRef = doc(collection(fireStoreDb, "medical_services"), newExam);
      await setDoc(docRef, { id: newExam, type: "exam" });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["medical_services", variables],
      });
    },
    onError: (error) => {
      console.error(`Mutation error:`, error);
    },
  });
};
