import React from "react";
import { CalendarDate } from "@internationalized/date";

interface DateRangeDisplayProps {
  start: CalendarDate;
  end: CalendarDate;
}

export const DateRangeDisplay: React.FC<DateRangeDisplayProps> = ({
  start,
  end,
}) => {
  const formatDateToBrazilian = (date: CalendarDate): string => {
    return date.toDate("America/Sao_Paulo").toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <span className="max-md:hidden">
      {`${formatDateToBrazilian(start)} - ${formatDateToBrazilian(end)}`}
    </span>
  );
};
