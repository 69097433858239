import React from "react";
import { Chip, ChipProps } from "@nextui-org/react";

interface ChipWithIconProps extends Omit<ChipProps, "children"> {
  icon: React.ReactNode;
  label: string;
}

export const ChipWithIcon: React.FC<ChipWithIconProps> = ({
  icon,
  label,
  ...props
}) => (
  <Chip
    size="sm"
    variant="bordered"
    classNames={{
      content: "flex gap-1 items-center",
    }}
    color="default"
    {...props}
  >
    {icon}
    {label}
  </Chip>
);
