import { useQuery } from "@tanstack/react-query";
import { collection, getDocs } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { HealthInsurance } from "../../models/HealthInsurance";

const fetchHealthInsurances = async (): Promise<HealthInsurance[]> => {
  const querySnapshot = await getDocs(
    collection(fireStoreDb, "healthInsurances")
  );
  return querySnapshot.docs.map((doc) => ({
    id: doc.data().id,
  }));
};

export const useHealthInsurancesQuery = () => {
  return useQuery<HealthInsurance[], Error>({
    queryKey: ["healthInsurances"],
    queryFn: fetchHealthInsurances,
  });
};
