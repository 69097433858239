import { useQuery } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { UserInformation } from "../../models/UserInformation";

export const useUserDetailsQuery = (userId: string | undefined | null) => {
  return useQuery<UserInformation>({
    queryKey: ["users", userId],
    queryFn: async () => {
      const docRef = doc(fireStoreDb, "users", userId as string);
      const docSnap = await getDoc(docRef);
      return (docSnap.data() || {}) as UserInformation;
    },
    enabled: Boolean(userId),
  });
};
