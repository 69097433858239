import { Autocomplete as NextUIAutocomplete } from "@nextui-org/react";

export const Autocomplete = (props: any) => (
  <NextUIAutocomplete
    {...props}
    classNames={{
      popoverContent: "max-md:max-h-40",
      clearButton: "w-12",
    }}
  />
);
