import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox, Add } from "@mui/icons-material";
import { CustomModal } from "../CustomModalInner";
import {
  useExamsQuery,
  useAddExam,
} from "../../hooks/queries/useMedicalServicesQuery";
import { toast } from "react-toastify";

export interface ExamAutoCompleteProps {
  value: string[];
  setValue: (value: string[]) => void;
}

export const ExamAutoComplete: React.FC<ExamAutoCompleteProps> = ({
  value,
  setValue,
}) => {
  const [addExamModalActive, setAddExamModalActive] = useState(false);
  const [newExam, setNewExam] = useState<string>("");

  // Query to fetch exams
  const { data: examsQuery, isLoading, refetch } = useExamsQuery();

  // Mutation for adding a new exam
  const { mutateAsync: addExam, isPending: isAddingExam } = useAddExam();

  const handleAddExam = async () => {
    if (!newExam) return;

    try {
      await addExam(newExam);
      setValue([...value, newExam]);
      setNewExam("");
      setAddExamModalActive(false);
      refetch();
      toast.success("Novo exame adicionado com sucesso!");
    } catch (error) {
      console.error("Error adding new exam: ", error);
      toast.error("Erro ao adicionar novo exame");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Autocomplete
        multiple
        options={examsQuery || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.id}
          </li>
        )}
        value={examsQuery?.filter((exam) => value.includes(exam.id)) || []}
        onChange={(_event, newValue) => {
          setValue(newValue.map((v) => v.id));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Exames"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        fullWidth
        loading={isLoading}
      />
      <div>
        <IconButton
          color="primary"
          onClick={() => setAddExamModalActive(true)}
          disabled={isLoading || isAddingExam}
        >
          <Add />
        </IconButton>
      </div>
      <CustomModal
        open={addExamModalActive}
        onClose={() => setAddExamModalActive(false)}
      >
        <div className="p-5 d-flex flex-column gap-3">
          <TextField
            autoComplete="new-password"
            required
            label="Novo Exame"
            variant="outlined"
            value={newExam}
            onChange={({ target }) => setNewExam(target.value)}
            disabled={isAddingExam}
          />
          <Button
            variant="contained"
            onClick={handleAddExam}
            disabled={isAddingExam || !newExam}
          >
            {isAddingExam ? <CircularProgress size={24} /> : "Adicionar"}
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};
