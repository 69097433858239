import React from "react";
import {
  TextField,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ProviderInformation } from "../../models/ProviderInformation";
import { formatCurrency } from "../../utils/formatCurrency";

interface ServiceDetailsInputProps {
  details: ProviderInformation;
  setDetails: React.Dispatch<React.SetStateAction<ProviderInformation>>;
}

export const ServiceDetailsInput: React.FC<ServiceDetailsInputProps> = ({
  details,
  setDetails,
}) => {
  const handleServiceChange = (
    serviceId: string,
    field: string,
    value: string | number | boolean
  ) => {
    setDetails((prevDetails: any) => {
      if ("schedules" in prevDetails) {
        // ProviderInformation case
        return {
          ...prevDetails,
          schedules: {
            ...prevDetails.schedules,
            appointments: {
              ...prevDetails.schedules?.appointments,
              services: {
                ...prevDetails.schedules?.appointments?.services,
                [serviceId]: {
                  ...prevDetails.schedules?.appointments?.services?.[serviceId],
                  [field]: value,
                },
              },
            },
          },
        };
      }
    });
  };

  const getServiceDetails = (serviceId: string) => {
    if ("schedules" in details) {
      // ProviderInformation case
      return details.schedules.appointments?.services?.[serviceId] || {};
    }
  };

  const services = Object.keys(details.schedules.appointments?.services || {});

  return (
    <div>
      <Typography variant="h6">Detalhes dos Serviços</Typography>
      <Grid container spacing={2}>
        {services.map((serviceId: string) => {
          const serviceDetails = getServiceDetails(serviceId);
          return (
            <React.Fragment key={serviceId}>
              <Grid item xs={12} md={3}>
                <TextField
                  label={`Nome ${serviceId}`}
                  variant="outlined"
                  value={serviceDetails?.name || ""}
                  onChange={(e) =>
                    handleServiceChange(serviceId, "name", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label={`Duração ${serviceId} (minutos)`}
                  type="number"
                  variant="outlined"
                  value={serviceDetails?.duration || ""}
                  onChange={(e) =>
                    handleServiceChange(
                      serviceId,
                      "duration",
                      Number(e.target.value)
                    )
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label={`Valor ${serviceId}`}
                  variant="outlined"
                  value={serviceDetails?.price || ""}
                  onChange={(e) => {
                    const formattedValue = formatCurrency(e.target.value);
                    handleServiceChange(serviceId, "price", formattedValue);
                  }}
                  fullWidth
                  InputProps={{
                    inputProps: { inputMode: "numeric" },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serviceDetails?.acceptsHealthInsurances || false}
                      onChange={(e) =>
                        handleServiceChange(
                          serviceId,
                          "acceptsHealthInsurances",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Aceita convênio"
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};
