// organisms/AppointmentDetails.tsx
import React from "react";
import { Appointment } from "../../../models/Appointment";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { getColorByStatusCode } from "../../../utils/getColorByStatusCode";
import { useAuth } from "../../../contexts/auth.context";
import { useUserDetailsQuery } from "../../../hooks/queries/useUserDetailsQuery";

import { AppointmentStatusBox } from "../../Molecules/AppointmentStatusBox";
import { PatientDetails } from "../PatientDetails";
import { ProviderDetails } from "../ProviderDetails";
import { AppointmentActions } from "../../Templates/AppointmentActions";
import { ClipboardPlus, Stethoscope, Timer } from "lucide-react";
import { Clinic } from "../../../models/Clinic";

interface AppointmentDetailsProps {
  appointment: Appointment;
  provider?: ProviderInformation;
  clinic?: Clinic;
  formattedDate: string;
  formattedTime: string;
}

export const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({
  appointment,
  provider,
  clinic,
  formattedDate,
  formattedTime,
}) => {
  const entityDetails = provider || clinic;

  const { currentUser } = useAuth();
  const { description } = getColorByStatusCode(appointment.status);

  const { data: userInfo, isLoading: isUserLoading } = useUserDetailsQuery(
    appointment?.userId
  );

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      `Olá, aqui estão os detalhes do seu agendamento Zip Saúde:\n\nData: ${formattedDate}\nHora: ${formattedTime}\nMédico: ${
        provider?.name || "Verificar na clínica"
      }`
    );
    const phoneNumber = userInfo?.phoneNumber;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleEmailClick = () => {
    const subject = encodeURIComponent("Zip Saúde | Detalhes do Agendamento");
    const body = encodeURIComponent(
      `Olá, aqui estão os detalhes do seu agendamento:\n\nData: ${formattedDate}\nHora: ${formattedTime}\nMédico: ${
        provider?.name || "Verificar na clínica"
      }`
    );
    const email = userInfo?.email;
    const mailtoUrl = `mailto:${email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoUrl;
  };

  return (
    <div className="flex max-md:flex-col gap-4 items-start justify-center w-full">
      <div className="flex flex-col justify-center items-start bg-white rounded-large gap-2 p-2 w-full">
        <div className="w-full bg-default-100 rounded-medium p-2">
          <h2 className="text-default-700 font-semibold text-sm">
            Informações
          </h2>
        </div>

        <AppointmentStatusBox
          date={formattedDate}
          time={formattedTime}
          status={appointment.status}
          description={description}
        />
        <div className="flex flex-col gap-4 w-full">
          {currentUser.role === "provider" ? (
            <ProviderDetails
              appointment={appointment}
              userInfo={userInfo}
              isUserLoading={isUserLoading}
              timezone={currentUser.timezone}
            />
          ) : (
            <PatientDetails
              appointment={appointment}
              provider={provider}
              clinic={clinic}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>

      {appointment.examId && (
        <div className="flex flex-col justify-center items-start bg-white rounded-large gap-2 p-2 w-full">
          <div className="w-full bg-default-100 rounded-medium p-2">
            <h2 className="text-default-700 font-semibold text-sm">Exame</h2>
          </div>

          <div className="flex max-md:flex-col w-full gap-1">
            <div className="flex max-md:flex-row flex-col w-full gap-1">
              <div className="flex max-md:flex-col items-center justify-start p-2 gap-1 bg-secondary-50 rounded-medium max-md:w-full h-full">
                <ClipboardPlus className="text-secondary-600 w-6 h-6" />
                <span className="text-secondary-900 font-semibold">
                  {appointment.examId}
                </span>
              </div>
              <div className="flex max-md:flex-col items-center justify-start p-2 gap-1 bg-warning-50 rounded-medium max-md:w-full h-full">
                <Timer className="text-warning-500 w-6 h-6" />
                <span className="text-warning-900 font-semibold">
                  {
                    entityDetails?.schedules?.exams?.exams?.[appointment.examId]
                      .duration
                  }{" "}
                  minutos
                </span>
              </div>
            </div>

            {currentUser.role === "customer" && (
              <div className="flex flex-col items-center justify-center p-2 gap-1 bg-primary-50 rounded-medium w-full max-md:w-full">
                <Stethoscope className="text-primary-500 w-6 h-6" />
                <span className="text-primary-900 font-semibold text-center">
                  {provider?.name || "Verifique o profissional com a clínica"}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      {description !== "Cancelado" && (
        <AppointmentActions
          appointment={appointment}
          provider={provider as ProviderInformation}
          userInfo={userInfo}
          currentUser={currentUser}
          onWhatsAppClick={handleWhatsAppClick}
          onEmailClick={handleEmailClick}
        />
      )}
    </div>
  );
};
