import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../../contexts/auth.context";

export const ProtectedRoutes = ({
  allowedRoles,
}: {
  allowedRoles: string[];
}) => {
  const { currentUser, signed } = useAuth();

  if (!signed) {
    return <Navigate to="/login" replace />;
  }

  if (!allowedRoles.includes(currentUser.role)) {
    return (
      <Navigate
        to={currentUser.role === "provider" ? "/appointments" : "/"}
        replace
      />
    );
  }

  return <Outlet />;
};
