import { useQuery } from "@tanstack/react-query";
import { getDoc, doc } from "firebase/firestore";
import { fireStoreDb, auth } from "../../services/firebase/firebase";
import { UserInformation } from "../../models/UserInformation";

export const useUserInformationQuery = () => {
  const userId = auth.currentUser?.uid;

  return useQuery<UserInformation, Error>({
    queryKey: ["users", "current", userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user logged in");
      const docRef = doc(fireStoreDb, "users", userId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) throw new Error("User not found");
      return docSnap.data() as UserInformation;
    },
    enabled: Boolean(userId),
  });
};
