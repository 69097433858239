import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useCompleteAppointment } from "../../../hooks/queries/useCompleteAppointment";
import { toast } from "react-toastify";

export const CompleteAppointmentModal = ({
  isOpen,
  description,
  providerName,
  appointmentId,
  onOpenChange,
}: {
  isOpen: boolean;
  description: string;
  providerName: string;
  appointmentId: string;
  onOpenChange: () => void;
}) => {
  const completeAppointmentMutation = useCompleteAppointment();

  const completeAppointment = (onClose: () => void) => {
    completeAppointmentMutation.mutate(
      { id: appointmentId, providerName },
      {
        onSuccess: () => {
          toast.success("Agendamento concluído com sucesso!");
          onClose();
        },
        onError: (error) => {
          toast.error("Erro ao concluir agendamento. Tente novamente");
          console.error("Error completing appointment: ", error);
        },
      }
    );
  };

  return (
    <Modal
      backdrop="blur"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      classNames={{
        backdrop: "bg-black/75",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className=" text-secondary-900 max-md:text-center max-md:w-full">
              Você confirma que quer concluir esse agendamento?
            </ModalHeader>
            <ModalBody>
              <p className="max-md:text-center">{description}</p>
            </ModalBody>
            <ModalFooter className="gap-4">
              <Button
                color="danger"
                variant="light"
                onPress={onClose}
                className="font-medium"
              >
                Cancelar
              </Button>
              <Button
                color="secondary"
                variant="solid"
                onPress={() => completeAppointment(onClose)}
                isLoading={completeAppointmentMutation.isPending}
                isDisabled={completeAppointmentMutation.isPending}
              >
                Confirmar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
