import React, { useState } from "react";
import { Button } from "@nextui-org/react";
import { useAuth } from "../../../contexts/auth.context";
import { Appointment } from "../../../models/Appointment";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { AnimatePresence, motion } from "framer-motion";
import { Check } from "lucide-react";
import { GoogleCalendar } from "../../../assets/GoogleCalendar";
import { DateUtils } from "../../../utils/dateUtils";
import { toast } from "react-toastify";

interface GoogleCalendarButtonProps {
  appointment: Appointment;
  provider: ProviderInformation;
  endTime: string;
}

export const GoogleCalendarButton: React.FC<GoogleCalendarButtonProps> = ({
  appointment,
  provider,
  endTime,
}) => {
  const { handleGoogleSignIn, googleAccessToken, googleAccessTokenExpiration } =
    useAuth();
  const [isAddingToCalendar, setIsAddingToCalendar] = useState(false);
  const [isAddedToCalendar, setIsAddedToCalendar] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const isTokenValid = () => {
    if (!googleAccessToken || !googleAccessTokenExpiration) return false;
    const expirationDate = DateUtils.fromDBFormat(googleAccessTokenExpiration);
    return DateUtils.now().isBefore(expirationDate);
  };

  const handleAuthentication = async () => {
    setIsAuthenticating(true);
    try {
      const newToken = await handleGoogleSignIn();
      setIsAuthenticating(false);
      return newToken;
    } catch (error) {
      console.error("Error during authentication:", error);
      setIsAuthenticating(false);
      throw error;
    }
  };

  const addEventToGoogleCalendar = async (event: any, token: string) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(event),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsAddedToCalendar(true);
      toast.success("Evento adicionado ao Google Calendar com sucesso!");
    } catch (error) {
      console.error("Error adding event to Google Calendar:", error);
      if (error instanceof Error) {
        if (error.message.includes("User is not authenticated")) {
          toast.error(
            "Por favor, faça login para adicionar eventos ao Google Calendar."
          );
        } else if (error.message.includes("access_denied")) {
          toast.error(
            "O acesso ao Google Calendar foi negado. Verifique as permissões e tente novamente."
          );
        } else if (error.message.includes("auth/popup-closed-by-user")) {
          toast.error(
            "Você saiu da tela de login. Por favor, tente novamente."
          );
        } else {
          toast.error(`Erro: ${error.message}`);
        }
      } else {
        toast.error(
          "Erro ao adicionar evento ao Google Calendar. Por favor, tente novamente."
        );
      }
      return false;
    }
  };

  const handleAddToCalendar = async () => {
    setIsAddingToCalendar(true);
    try {
      let token = googleAccessToken;
      if (!isTokenValid()) {
        token = (await handleAuthentication()) as string;
      }

      const event = {
        summary: `Zip Saúde | ${appointment.examId ? "Exame" : "Consulta"}`,
        description: `Consulta com ${provider.name}`,
        start: {
          dateTime: appointment.selectedOption as string,
          timeZone: provider.timezone,
        },
        end: {
          dateTime: endTime,
          timeZone: provider.timezone,
        },
      };

      await addEventToGoogleCalendar(event, token as string);
    } catch (error) {
      console.error("Error adding event to calendar:", error);
      toast.error("Erro ao adicionar evento. Por favor, tente novamente.");
    } finally {
      setIsAddingToCalendar(false);
    }
  };

  return (
    <Button
      color={isAddedToCalendar ? "success" : "default"}
      variant="bordered"
      size="lg"
      radius="md"
      onPress={handleAddToCalendar}
      isLoading={isAddingToCalendar || isAuthenticating}
      isDisabled={isAddedToCalendar}
      startContent={
        !isAddingToCalendar &&
        !isAuthenticating && (
          <AnimatePresence mode="wait">
            {isAddedToCalendar ? (
              <motion.div
                key="check"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
              >
                <Check />
              </motion.div>
            ) : (
              <GoogleCalendar />
            )}
          </AnimatePresence>
        )
      }
      className={`w-full ${
        !isAddedToCalendar && !isAddingToCalendar
          ? "bg-white border-default-200"
          : ""
      }`}
    >
      <AnimatePresence mode="wait">
        <motion.span
          key={
            isAddedToCalendar ? "added" : isAddingToCalendar ? "adding" : "add"
          }
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
        >
          {isAddedToCalendar
            ? "Adicionado à Agenda"
            : isAddingToCalendar
            ? "Adicionando..."
            : "Adicionar na Agenda"}
        </motion.span>
      </AnimatePresence>
    </Button>
  );
};
