import React, { useState } from "react";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { ActionSection } from "../../Molecules/ActionSection";
import { ProviderActions } from "../../Organisms/ProviderActions";
import { CancelAppointment } from "../../Organisms/CancelAppointment";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { UserInformation } from "../../../models/UserInformation";
import { Button } from "@nextui-org/react";
import { Review } from "../../Molecules/Review";
import { DateUtils } from "../../../utils/dateUtils";
import { GoogleCalendarButton } from "../../Atoms/GoogleCalendarButton";

interface AppointmentActionsProps {
  appointment: Appointment;
  provider: ProviderInformation;
  currentUser: AuthContextUserProps;
  userInfo: UserInformation | undefined;
  onWhatsAppClick: () => void;
  onEmailClick: () => void;
}

export const AppointmentActions: React.FC<AppointmentActionsProps> = ({
  appointment,
  provider,
  currentUser,
  userInfo,
  onWhatsAppClick,
  onEmailClick,
}) => {
  const [isReviewVisible, setIsReviewVisible] = useState(false);

  const actionTitle = isReviewVisible ? "Avaliar" : "Ações";

  const canReview =
    appointment.status === AppointmentStatus.COMPLETED &&
    !appointment.hasReview;

  const endTime = DateUtils.calculateEndTime(
    appointment.selectedOption as string,
    appointment.examId ? "exam" : "appointment",
    appointment.examId || appointment.serviceId,
    appointment.examId
      ? provider.schedules.exams!
      : provider.schedules.appointments!,
    provider.timezone
  );

  return (
    <ActionSection title={actionTitle}>
      {appointment.status === AppointmentStatus.CONFIRMED && (
        <GoogleCalendarButton
          appointment={appointment}
          provider={provider}
          endTime={endTime}
        />
      )}
      {currentUser.role === "provider" && (
        <ProviderActions
          appointment={appointment}
          provider={provider}
          userInfo={userInfo}
          onWhatsAppClick={onWhatsAppClick}
          onEmailClick={onEmailClick}
        />
      )}
      {currentUser.role === "customer" && canReview && (
        <>
          {!isReviewVisible ? (
            <Button
              color="primary"
              onClick={() => setIsReviewVisible(true)}
              className="w-full"
            >
              Avaliar Médico
            </Button>
          ) : (
            <Review appointment={appointment} />
          )}
        </>
      )}
      {!isReviewVisible && (
        <CancelAppointment
          appointment={appointment}
          currentUser={currentUser}
        />
      )}
    </ActionSection>
  );
};
