import { Banknote, CreditCard, Nfc, Receipt } from "lucide-react";

export enum PaymentMethod {
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  PIX = "PIX",
  BOLETO = "BOLETO",
  CASH = "CASH",
}

export const PAYMENT_METHODS = Object.values(PaymentMethod);

export const PAYMENT_METHOD_LABELS: Record<PaymentMethod, string> = {
  [PaymentMethod.CREDIT_CARD]: "Cartão de Crédito",
  [PaymentMethod.DEBIT_CARD]: "Cartão de Débito",
  [PaymentMethod.PIX]: "Pix",
  [PaymentMethod.BOLETO]: "Boleto",
  [PaymentMethod.CASH]: "Dinheiro",
};

export const PaymentMethodIcon: React.FC<{ method: PaymentMethod }> = ({
  method,
}) => {
  const iconMap = {
    [PaymentMethod.CREDIT_CARD]: CreditCard,
    [PaymentMethod.DEBIT_CARD]: CreditCard,
    [PaymentMethod.PIX]: Nfc,
    [PaymentMethod.BOLETO]: Receipt,
    [PaymentMethod.CASH]: Banknote,
  };

  const Icon = iconMap[method];
  return Icon ? <Icon className="w-4" /> : null;
};
