import { Fade, Modal, ModalProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ModalStyled = styled(Modal)`
    border: 0 solid black;
    outline: 0;
    overflow-y: auto;
    height: 100vh;
    display: flex;
    padding: 20px 0;
`;

const ModalInner = styled.div`
    background-color: white;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* align-items: center; */
    min-width: 50vw;

    /* Centralize */
    margin: auto;
    border: 0 solid black;
    border-radius: 5px;
    overflow: hidden;
    outline: 0;
`;

export interface CustomModalProps extends ModalProps {
    modalInnerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const CustomModal: React.FC<CustomModalProps> = ({ children, open, modalInnerProps, ...props }) => {
    return <ModalStyled {...props} open={open}>
        <Fade in={open}>
            <ModalInner {...modalInnerProps}>
                {children}
            </ModalInner>
        </Fade>
    </ModalStyled>
}
