import { useState, useMemo } from "react";
import { State } from "../models/State";
import { City } from "../models/City";
import { useIBGEStatesQuery } from "./queries/useIBGEStates";
import { useProviderQuery } from "./queries/useProviderQuery";
import { useUserLocation } from "./queries/useUserLocation";
import { ProviderInformation } from "../models/ProviderInformation";
import { Clinic } from "../models/Clinic";
import { useIBGECitiesQuery } from "./queries/useIBGECities";
import { useMedicalServicesQuery } from "./queries/useMedicalServicesQuery";
import { useAuth } from "../contexts/auth.context";
import { MedicalService } from "../models/MedicalService";
import { useClinicQuery } from "./queries/useClinicQuery";
import { queryClient } from "../libs/queryClient";
import { harmonizeLocation, reverseGeocode } from "../utils/locationUtils";

export const useProviderSearch = () => {
  const { currentUser } = useAuth();

  const [selectedState, setSelectedState] = useState<State | undefined>(
    currentUser?.location?.state as State
  );
  const [selectedCity, setSelectedCity] = useState<City | undefined>(
    currentUser?.location?.city as City
  );
  const [selectedMedicalService, setSelectedMedicalService] = useState<
    MedicalService | undefined
  >();
  const [filteredProviders, setFilteredProviders] = useState<
    ProviderInformation[]
  >([]);
  const [filteredClinics, setFilteredClinics] = useState<Clinic[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);

  const {
    data: states,
    isLoading: isLoadingStates,
    refetch: refetchStates,
  } = useIBGEStatesQuery();
  const { data: cities, isLoading: isLoadingCities } =
    useIBGECitiesQuery(selectedState);
  const { data: providers, isLoading: isLoadingProviders } = useProviderQuery();
  const { data: clinics, isLoading: isLoadingClinics } = useClinicQuery();
  const { data: medicalServices, isLoading: isLoadingMedicalServices } =
    useMedicalServicesQuery();

  const { manualLocationMutation, isManualLocationMutationLoading } =
    useUserLocation();

  const stateItems = useMemo(() => {
    return (states?.map((state) => ({
      key: state.id,
      value: state.id,
      label: state.nome,
    })) || []) as { key: string; value: string; label: string }[];
  }, [states]);

  const cityItems = useMemo(() => {
    return (cities?.map((city: City) => ({
      key: city.id,
      value: city.id,
      label: city.nome,
    })) || []) as { key: string; value: string; label: string }[];
  }, [cities]);

  const medicalServiceItems =
    medicalServices?.map((medicalService) => ({
      key: medicalService.id,
      value: medicalService.id,
      label: medicalService.id,
      type: medicalService.type,
    })) || [];

  const handleStateChange = (stateId: string) => {
    const state = states?.find((s) => s.id == stateId);
    setSelectedState(state);
    setSelectedCity(undefined);
    setFilteredProviders([]);
    setFilteredClinics([]);
  };

  const handleCityChange = (cityId: string) => {
    const city = cities?.find((c: City) => c.id == cityId);
    setSelectedCity(city);
    setFilteredProviders([]);
    setFilteredClinics([]);
  };

  const handleMedicalServiceChange = (medicalServiceId: string) => {
    const medicalService = medicalServices?.find(
      (m: MedicalService) => m.id == medicalServiceId
    );
    setSelectedMedicalService(medicalService);
    setFilteredProviders([]);
    setFilteredClinics([]);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedMedicalService) return;

    if (selectedMedicalService.type === "specialty" && providers) {
      const results = providers.filter((provider) => {
        const matchesState = selectedState
          ? provider.relatedClinic?.state?.id === selectedState.id
          : true;
        const matchesCity = selectedCity
          ? provider.relatedClinic?.city?.id === selectedCity.id
          : true;
        const matchesSpecialty = provider.specialties?.includes(
          selectedMedicalService.id
        );
        return matchesState && matchesCity && matchesSpecialty;
      });
      setFilteredProviders(results);
      setFilteredClinics([]);
    } else if (selectedMedicalService.type === "service" && providers) {
      const results = providers.filter((provider) => {
        const matchesState = selectedState
          ? provider.relatedClinic?.state?.id === selectedState.id
          : true;
        const matchesCity = selectedCity
          ? provider.relatedClinic?.city?.id === selectedCity.id
          : true;
        const matchesService =
          provider.schedules?.appointments?.services?.[
            selectedMedicalService.id
          ] !== undefined;
        return matchesState && matchesCity && matchesService;
      });
      setFilteredProviders(results);
      setFilteredClinics([]);
    } else if (selectedMedicalService.type === "exam" && providers && clinics) {
      const filteredProviders = providers.filter((provider) => {
        const matchesState = selectedState
          ? provider.relatedClinic?.state?.id === selectedState.id
          : true;
        const matchesCity = selectedCity
          ? provider.relatedClinic?.city?.id === selectedCity.id
          : true;
        const matchesExam =
          provider.schedules?.exams?.exams?.[selectedMedicalService.id] !==
          undefined;
        return matchesState && matchesCity && matchesExam;
      });

      const filteredClinics = clinics.filter((clinic: Clinic) => {
        const matchesState = selectedState
          ? clinic.state?.id === selectedState.id
          : true;
        const matchesCity = selectedCity
          ? clinic.city?.id === selectedCity.id
          : true;
        const matchesExam =
          clinic.schedules?.exams?.exams?.[selectedMedicalService.id] !==
          undefined;

        return matchesState && matchesCity && matchesExam;
      });

      setFilteredProviders(filteredProviders);
      setFilteredClinics(filteredClinics);
    }
  };

  const handleLocationSearch = async (latitude: number, longitude: number) => {
    setLocationLoading(true);
    try {
      const geocodedLocation = await reverseGeocode(latitude, longitude);
      const allStates = states || (await refetchStates()).data;
      if (!allStates) throw new Error("Failed to fetch states");

      const harmonizedLocation = await harmonizeLocation(
        geocodedLocation,
        allStates,
        async (state) => {
          const cities = await queryClient.fetchQuery({
            queryKey: ["IBGECities", state.id],
            queryFn: async () => {
              const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.id}/municipios`
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json() as Promise<City[]>;
            },
          });
          return cities || [];
        }
      );

      if (harmonizedLocation.state && harmonizedLocation.city) {
        setSelectedState(harmonizedLocation.state);
        setSelectedCity(harmonizedLocation.city);
        await handleManualLocationUpdate(
          harmonizedLocation.state,
          harmonizedLocation.city
        );
      }
    } catch (error) {
      console.error(error);
      // Handle the error appropriately, e.g., show a notification to the user
    } finally {
      setLocationLoading(false);
    }
  };

  const handleManualLocationUpdate = async (state: State, city: City) => {
    try {
      await manualLocationMutation.mutateAsync({ state, city });
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  return {
    selectedState,
    selectedCity,
    selectedMedicalService,
    filteredProviders,
    filteredClinics,
    stateItems,
    cityItems,
    medicalServiceItems,
    isLoadingStates,
    isLoadingCities,
    isLoadingMedicalServices,
    isLoadingProviders,
    isLoadingClinics,
    isManualLocationMutationLoading,
    locationLoading,
    handleStateChange,
    handleCityChange,
    handleMedicalServiceChange,
    handleSearch,
    handleLocationSearch,
    handleManualLocationUpdate,
  };
};
