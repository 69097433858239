import React, { useState } from "react";
import { Divider } from "@nextui-org/react";

import { UserInfo } from "../../Molecules/UserInfo";
import { SearchProvider } from "../../Molecules/SearchProvider";
import { ProvidersList } from "../../Organisms/ProvidersList";
import { ClinicsList } from "../../Organisms/ClinicsList";
import { useProviderSearch } from "../../../hooks/useProviderSearch";
import { useUserInformationQuery } from "../../../hooks/queries/userInformationQuery";
import { compareStrings } from "../../../utils/compareStrings";
import { EmptyResultCard } from "../../Molecules/EmptyResultCard";

export const Home: React.FC = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const {
    selectedState,
    selectedCity,
    selectedMedicalService,
    filteredProviders,
    filteredClinics,
    stateItems,
    cityItems,
    medicalServiceItems,
    isLoadingStates,
    isLoadingCities,
    isLoadingMedicalServices,
    locationLoading,
    handleStateChange,
    handleCityChange,
    handleMedicalServiceChange,
    handleSearch,
    handleLocationSearch,
  } = useProviderSearch();

  const { data: userInfo, isLoading: isLoadingUserInfo } =
    useUserInformationQuery();

  const handleSearchWrapper = (e: React.FormEvent) => {
    handleSearch(e);
    setHasSearched(true);
  };

  const handleStateChangeWrapper = (stateId: string) => {
    handleStateChange(stateId);
    setHasSearched(false);
  };

  const handleCityChangeWrapper = (cityId: string) => {
    handleCityChange(cityId);
    setHasSearched(false);
  };

  const handleMedicalServiceChangeWrapper = (medicalServiceId: string) => {
    handleMedicalServiceChange(medicalServiceId);
    setHasSearched(false);
  };

  const renderSearchResults = () => {
    if (selectedMedicalService?.type === "specialty") {
      return filteredProviders.length !== 0 ? (
        <ProvidersList
          medicalService={selectedMedicalService.id}
          providers={filteredProviders}
          isGeneralSearch
        />
      ) : (
        <EmptyResultCard
          medicalService={selectedMedicalService}
          onPress={() => handleMedicalServiceChangeWrapper("")}
        />
      );
    } else if (selectedMedicalService?.type === "service") {
      return filteredProviders.length !== 0 ? (
        <ProvidersList
          medicalService={selectedMedicalService.id}
          providers={filteredProviders}
          isGeneralSearch={false}
        />
      ) : (
        <EmptyResultCard
          medicalService={selectedMedicalService}
          onPress={() => handleMedicalServiceChangeWrapper("")}
        />
      );
    } else if (selectedMedicalService?.type === "exam") {
      return filteredClinics.length !== 0 ? (
        <ClinicsList
          clinics={filteredClinics}
          medicalService={selectedMedicalService.id}
          examId={selectedMedicalService.id}
        />
      ) : (
        <EmptyResultCard
          medicalService={selectedMedicalService}
          onPress={() => handleMedicalServiceChangeWrapper("")}
        />
      );
    }
    return null;
  };

  return (
    <main className="flex flex-col justify-center gap-8 items-center min-h-svh p-4">
      <UserInfo
        userInfo={userInfo}
        description="Bem-vindo(a) ao painel da Zip!"
        isLoading={isLoadingUserInfo}
      />

      <div className="flex flex-col justify-center gap-4 max-md:w-full">
        <span className="text-xl font-medium text-center">
          {hasSearched && selectedMedicalService
            ? `${selectedMedicalService.id} perto de mim`
            : "Agende uma consulta ou um exame"}
        </span>

        <div className="flex flex-col gap-4">
          <SearchProvider
            selectedState={selectedState}
            selectedCity={selectedCity}
            selectedMedicalService={selectedMedicalService}
            stateItems={stateItems.sort((a, b) =>
              compareStrings(a.label, b.label)
            )}
            cityItems={cityItems.sort((a, b) =>
              compareStrings(a.label, b.label)
            )}
            medicalServiceItems={medicalServiceItems.sort((a, b) =>
              compareStrings(a.label, b.label)
            )}
            onStateChange={handleStateChangeWrapper}
            onCityChange={handleCityChangeWrapper}
            onMedicalServiceChange={handleMedicalServiceChangeWrapper}
            onLocationSearch={handleLocationSearch}
            onSearch={handleSearchWrapper}
            isDisabled={
              !selectedState || !selectedCity || !selectedMedicalService
            }
            isLoadingStates={isLoadingStates}
            isLoadingCities={isLoadingCities}
            isLoadingMedicalServices={isLoadingMedicalServices}
            locationSearchLoading={locationLoading}
          />

          {hasSearched && (
            <>
              <Divider className="bg-default-300 h-1 rounded-full" />
              {renderSearchResults()}
            </>
          )}
        </div>
      </div>
    </main>
  );
};
