import React from "react";
import {
  PAYMENT_METHOD_LABELS,
  PaymentMethodIcon,
  PaymentMethod,
} from "../../../models/PaymentMethod";
import { ChipWithIcon } from "../../Atoms/ChipWithIcon";

interface PaymentMethodListProps {
  methods: PaymentMethod[];
}

export const PaymentMethodList: React.FC<PaymentMethodListProps> = ({
  methods,
}) => (
  <div className="flex gap-1 flex-wrap">
    {methods.map((method) => (
      <ChipWithIcon
        key={method}
        icon={<PaymentMethodIcon method={method} />}
        label={PAYMENT_METHOD_LABELS[method]}
      />
    ))}
  </div>
);
