import React from "react";
import { Chip } from "@nextui-org/react";
import { Star } from "lucide-react";
import { PaymentMethodList } from "../../Molecules/PaymentMethodsList";
import { ContactInfoGrid } from "../../Molecules/ContactInfoGrid";
import { PaymentMethod } from "../../../models/PaymentMethod";
import { EntityInfo } from "../../Molecules/EntityInfo";
import { Clinic } from "../../../models/Clinic";
import { ProviderInformation } from "../../../models/ProviderInformation";

interface ContactInfo {
  phoneNumber: string;
  name: string;
  address: string;
  timezone: string;
}

interface BookingHeaderProps {
  clinic?: Clinic;
  provider?: ProviderInformation;
  acceptedPaymentMethods?: PaymentMethod[];
  contactInfo: ContactInfo;
  userTimezone: string;
}

export const BookingHeader: React.FC<BookingHeaderProps> = ({
  clinic,
  provider,
  acceptedPaymentMethods,
  contactInfo,
  userTimezone,
}) => (
  <div className="flex flex-col w-full gap-3 p-2 bg-white rounded-large">
    <div className="flex justify-between items-start w-full">
      <EntityInfo
        clinic={clinic}
        provider={provider}
        titleClassName="text-sm font-medium text-default-900"
        descriptionClassName="text-xs font-semibold text-default-600"
        avatarClassName="w-14 h-14"
      />
      {provider !== undefined && (
        <Chip
          variant="flat"
          color="primary"
          startContent={<Star className="w-4" />}
          classNames={{ content: "font-semibold px-1" }}
        >
          {provider.averageRating}
        </Chip>
      )}
    </div>
    {provider && (
      <p className="text-xs text-default-700 px-0.5">{provider.readme}</p>
    )}
    {acceptedPaymentMethods && (
      <PaymentMethodList methods={acceptedPaymentMethods} />
    )}
    <ContactInfoGrid {...contactInfo} userTimezone={userTimezone} />
  </div>
);
