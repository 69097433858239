import React from "react";
import { AppointmentTabs } from "../../Molecules/AppointmentTabs";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { CalendarDate, today } from "@internationalized/date";
import { AppointmentsList } from "../../Organisms/AppointmentsList";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { Clinic } from "../../../models/Clinic";
import { UserInformation } from "../../../models/UserInformation";
import { AppointmentFilter } from "../../Molecules/AppointmentFilter";

interface MyAppointmentsTemplateProps {
  selectedStatus: AppointmentStatus;
  onStatusChange: (status: AppointmentStatus) => void;
  dateRange: { start: CalendarDate; end: CalendarDate };
  onDateRangeChange: (range: {
    start: CalendarDate;
    end: CalendarDate;
  }) => void;
  onResetDateRange: () => void;
  appointmentTypes: string[];
  onAppointmentTypesChange: (types: string[]) => void;
  appointments: Appointment[];
  providers: ProviderInformation[];
  clinics: Clinic[];
  currentUser: AuthContextUserProps;
  users: (UserInformation & { id: string })[];
  isLoading: boolean;
  isLoadingProviders: boolean;
  isFetchingNextPage: boolean;
  lastItemRef: (node?: Element | null | undefined) => void;
  handleSwipe: (status: AppointmentStatus) => void;
  isCustomFilterActive: boolean;
}

const DEFAULT_DATE_RANGE = {
  start: today("America/Sao_Paulo").subtract({ days: 7 }),
  end: today("America/Sao_Paulo").add({ days: 7 }),
};

const DEFAULT_APPOINTMENT_TYPES = ["Consultas", "Exames"];

export const MyAppointmentsTemplate: React.FC<MyAppointmentsTemplateProps> = ({
  selectedStatus,
  onStatusChange,
  dateRange,
  onDateRangeChange,
  onResetDateRange,
  appointmentTypes,
  onAppointmentTypesChange,
  appointments,
  providers,
  clinics,
  currentUser,
  users,
  isLoading,
  isLoadingProviders,
  isFetchingNextPage,
  lastItemRef,
  handleSwipe,
  isCustomFilterActive,
}) => {
  return (
    <main className="flex flex-col justify-start gap-4 items-start min-h-svh p-4">
      <div className="flex justify-between items-center w-full">
        <h1 className="font-medium text-lg max-md:hidden">Meus Agendamentos</h1>

        <div className="flex gap-2 justify-center items-center md:flex-row-reverse">
          <AppointmentTabs
            selectedStatus={selectedStatus}
            onStatusChange={onStatusChange}
          />
          <AppointmentFilter
            dateRange={dateRange}
            onDateRangeChange={onDateRangeChange}
            onReset={onResetDateRange}
            appointmentTypes={appointmentTypes}
            onAppointmentTypesChange={onAppointmentTypesChange}
            defaultDateRange={DEFAULT_DATE_RANGE}
            defaultAppointmentTypes={DEFAULT_APPOINTMENT_TYPES}
          />
        </div>
      </div>

      <AppointmentsList
        appointments={appointments}
        providers={providers}
        clinics={clinics}
        currentUser={currentUser}
        users={users}
        isLoading={isLoading}
        isLoadingProviders={isLoadingProviders}
        isFetchingNextPage={isFetchingNextPage}
        ref={lastItemRef}
        handleSwipe={handleSwipe}
        selectedStatus={selectedStatus}
        isCustomFilterActive={isCustomFilterActive}
      />
    </main>
  );
};
