import React from "react";
import { Box, Typography } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface ClinicHoursInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  required?: boolean;
}

export const ClinicTimeMaskInput: React.FC<ClinicHoursInputProps> = ({
  value,
  onChange,
  label,
  required = false,
}) => {
  const [startTime, endTime] = value.split(" às ") || ["", ""];

  const handleStartTimeChange = (newValue: any) => {
    onChange(`${newValue.format("HH:mm")} às ${endTime}`);
  };

  const handleEndTimeChange = (newValue: any) => {
    onChange(`${startTime} às ${newValue.format("HH:mm")}`);
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="caption">
        {label}
        {required && " *"}
      </Typography>
      <Box display="flex" alignItems="center">
        <DesktopTimePicker
          label="Início"
          value={startTime ? dayjs(startTime, "HH:mm") : null}
          onChange={handleStartTimeChange}
        />
        <Typography variant="body1" sx={{ mx: 1 }}>
          às
        </Typography>
        <DesktopTimePicker
          label="Fim"
          value={endTime ? dayjs(endTime, "HH:mm") : null}
          onChange={handleEndTimeChange}
        />
      </Box>
    </Box>
  );
};
