import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addDoc,
  collection,
  doc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { fireStoreDb, functions } from "../../services/firebase/firebase";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { DateUtils } from "../../utils/dateUtils";

interface SubmitReviewParams {
  providerId: string;
  userId: string;
  username: string;
  avatarUrl?: string;
  appointmentId: string;
  rating: number;
  comment: string;
}

const submitReview = async ({
  providerId,
  userId,
  appointmentId,
  username,
  avatarUrl,
  rating,
  comment,
}: SubmitReviewParams) => {
  const reviewRef = collection(fireStoreDb, "reviews");
  const providerRef = doc(fireStoreDb, "providers", providerId);

  await runTransaction(fireStoreDb, async (transaction) => {
    const providerDoc = await transaction.get(providerRef);
    if (!providerDoc.exists()) {
      throw "Provider document does not exist!";
    }
    const providerData = providerDoc.data();
    const newTotalReviews = (providerData.totalReviews || 0) + 1;
    let newAverageRating =
      ((providerData.averageRating || 0) * (newTotalReviews - 1) + rating) /
      newTotalReviews;

    newAverageRating = parseFloat(newAverageRating.toFixed(1));

    const createdAt = DateUtils.toDBFormat(DateUtils.now());

    const newReviewRef = await addDoc(reviewRef, {
      providerId,
      userId,
      username,
      avatarUrl,
      appointmentId,
      rating,
      comment,
      createdAt,
    });

    transaction.update(providerRef, {
      averageRating: newAverageRating,
      totalReviews: newTotalReviews,
    });

    await updateDoc(doc(fireStoreDb, "appointments", appointmentId), {
      hasReview: true,
    });

    const sendNotification = httpsCallable(
      functions,
      "sendAppointmentNotification"
    );
    sendNotification({
      appointmentId,
      userId: providerId,
      title: "Zip | Nova avaliação recebida",
      body: `Você recebeu uma nova avaliação. Toque para ver mais informações.`,
      URL: `/reviews/${newReviewRef.id}`,
    });

    return newReviewRef.id;
  });
};

export const useSubmitReview = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: submitReview,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["providers", "reviews", "appointments"],
      });
    },
    onError: (error) => {
      console.error("Error submitting review:", error);
      toast.error("Erro ao enviar avaliação. Por favor, tente novamente.");
    },
  });
};
