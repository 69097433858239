import { Clinic } from "../../../models/Clinic";
import { ClinicCard } from "../../Molecules/ClinicCard";
import { useNavigate } from "react-router-dom";

export const ClinicsList = ({
  clinics,
  examId,
  medicalService,
}: {
  clinics: Clinic[];
  examId: string;
  medicalService: string;
}) => {
  const navigate = useNavigate();

  const handleClinicClick = (clinic: Clinic) => {
    navigate(`/clinics/${clinic.id}/booking?exam=${examId}`);
  };

  return (
    <div className="flex max-lg:flex-col flex-wrap lg:max-w-5xl gap-2 items-center justify-center max-md:pb-20">
      {clinics.map((clinic) => (
        <ClinicCard
          key={clinic.id}
          clinic={clinic}
          medicalService={medicalService}
          onCardClick={() => handleClinicClick(clinic)}
        />
      ))}
    </div>
  );
};
