import { useDisclosure } from "@nextui-org/react";
import { Appointment, AppointmentStatus } from "../../../models/Appointment";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { CompleteAppointmentModal } from "../CompleteAppointmentModal";
import { AppointmentCommunicationButtons } from "../../Molecules/AppointmentCommunication";
import { ActionButton } from "../../Atoms/ActionButton";
import { UserInformation } from "../../../models/UserInformation";
import { DateUtils } from "../../../utils/dateUtils";
import { useEffect, useState } from "react";

interface ProviderActionsProps {
  appointment: Appointment;
  provider: ProviderInformation;
  userInfo?: UserInformation;
  onWhatsAppClick: () => void;
  onEmailClick: () => void;
}

export const ProviderActions: React.FC<ProviderActionsProps> = ({
  appointment,
  provider,
  userInfo,
  onWhatsAppClick,
  onEmailClick,
}) => {
  const {
    isOpen: isCompleteModalOpen,
    onOpen: onCompleteModalOpen,
    onOpenChange: onCompleteModalOpenChange,
  } = useDisclosure();

  const [isCompleteEnabled, setIsCompleteEnabled] = useState<boolean>(false);
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  useEffect(() => {
    const checkAppointmentStatus = () => {
      if (appointment.status !== AppointmentStatus.CONFIRMED) {
        setIsCompleteEnabled(false);
        setShouldRender(false);
        return;
      }

      setShouldRender(true);

      if (!appointment.selectedOption) {
        setIsCompleteEnabled(false);
        return;
      }

      const currentDate = DateUtils.now();
      const appointmentDate = DateUtils.fromDBFormat(
        appointment.selectedOption
      );

      if (appointmentDate && currentDate.isAfter(appointmentDate)) {
        setIsCompleteEnabled(true);
      } else {
        setIsCompleteEnabled(false);
      }
    };

    checkAppointmentStatus();
  }, [appointment.status, appointment.selectedOption]);

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <AppointmentCommunicationButtons
        onWhatsAppClick={onWhatsAppClick}
        onEmailClick={onEmailClick}
        isWhatsAppDisabled={!userInfo?.phoneNumber}
        isEmailDisabled={!userInfo?.email}
      />
      <ActionButton
        variant="flat"
        color="secondary"
        size="lg"
        radius="md"
        className="bg-secondary-100/50 text-secondary-600"
        onPress={onCompleteModalOpen}
        isDisabled={!isCompleteEnabled}
        label="Concluir Agendamento"
      />
      <CompleteAppointmentModal
        isOpen={isCompleteModalOpen}
        onOpenChange={onCompleteModalOpenChange}
        appointmentId={appointment.id as string}
        providerName={provider.name as string}
        description="Essa ação não poderá ser revertida e o ciclo de agendamento estará concluído"
      />
    </>
  );
};
