import React, { useCallback, useMemo } from "react";
import { Container, TimeSelectionCell } from "./styles";
import { Checkbox } from "@mui/material";
import { ProviderInformation } from "../../models/ProviderInformation";
import { Clinic } from "../../models/Clinic";
import { AppointmentStatus } from "../../models/Appointment";
import { DateUtils } from "../../utils/dateUtils";
import { weekDays } from "../../utils/weekDays";
import { calculateAvailableTimesPerDay } from "../FormModalLogic/utils";
import { produce } from "immer";
import { Schedule } from "../../models/Schedule";
import { isProviderInformation } from "../../utils/isProviderInformation";

export interface DisplayTimerProps {
  entity: ProviderInformation | Clinic;
  scheduleType: "appointments" | "exams";
  setEntity?: React.Dispatch<
    React.SetStateAction<ProviderInformation | Clinic>
  >;
  onHandleClickSelectionCell?: (day: Date, time: string) => void;
  selectedTimeDates?: string[];
}

export const DisplayTimer: React.FC<DisplayTimerProps> = ({
  entity,
  scheduleType,
  setEntity,
  onHandleClickSelectionCell,
  selectedTimeDates,
}) => {
  let schedule: Schedule | undefined;

  if (isProviderInformation(entity)) {
    schedule = entity.schedules[scheduleType];
  } else {
    // For Clinic, we only consider 'exams' schedule, and it might be undefined
    schedule = scheduleType === "exams" ? entity.schedules?.exams : undefined;
  }

  if (!schedule) return null;

  const timezone = "timezone" in entity ? entity.timezone : "America/Sao_Paulo";

  const availableTimes = useMemo(
    () =>
      calculateAvailableTimesPerDay(
        timezone,
        schedule,
        scheduleType === "exams"
      ),
    [timezone, schedule, scheduleType, entity]
  );

  const nextDays = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => {
      return DateUtils.now(timezone).add(i, "day").toDate();
    });
  }, [timezone]);

  const calculateHeader = useCallback((day: number) => {
    return weekDays[day];
  }, []);

  const handleClickSelectionCell = useCallback(
    (day: Date, time: string) => {
      if (onHandleClickSelectionCell) {
        onHandleClickSelectionCell(day, time);
        return;
      }
      if (!setEntity) return;

      setEntity((prevEntity) => {
        return produce(prevEntity, (draft: any) => {
          const prevSchedule = draft.schedules[scheduleType];
          if (!prevSchedule) return;

          if (!prevSchedule.disabledTimes) {
            prevSchedule.disabledTimes = {};
          }

          const currentDayDisabledTimes =
            prevSchedule.disabledTimes[day.getDay()] || [];
          const updatedDisabledTimes = currentDayDisabledTimes.includes(time)
            ? currentDayDisabledTimes.filter(
                (disabledTime: string) => disabledTime !== time
              )
            : [...currentDayDisabledTimes, time];

          prevSchedule.disabledTimes[day.getDay()] = updatedDisabledTimes;
        });
      });
    },
    [onHandleClickSelectionCell, setEntity, scheduleType]
  );

  const handleClickCheckBoxDay = useCallback(
    (day: Date) => {
      if (!setEntity) return;

      setEntity((prevEntity) => {
        return produce(prevEntity, (draft: any) => {
          const prevSchedule = draft.schedules[scheduleType];
          if (!prevSchedule) return;

          if (!prevSchedule.disabledDays) {
            prevSchedule.disabledDays = [];
          }

          const dayIndex = prevSchedule.disabledDays.indexOf(day.getDay());
          if (dayIndex > -1) {
            prevSchedule.disabledDays.splice(dayIndex, 1);
          } else {
            prevSchedule.disabledDays.push(day.getDay());
          }
        });
      });
    },
    [setEntity, scheduleType]
  );

  return (
    <Container>
      {nextDays.map((day) => (
        <div key={day.toString()} className="d-flex flex-column gap-3">
          <div className="d-flex flex-column">
            {setEntity !== undefined && (
              <Checkbox
                checked={!schedule.disabledDays?.includes(day.getDay())}
                onClick={() => handleClickCheckBoxDay(day)}
              />
            )}
            <p className="m-0">{calculateHeader(day.getDay())}</p>
            <small className="m-0">
              {DateUtils.toDisplayFormat(day, timezone).split(" ")[0]}
            </small>
          </div>
          <div className="d-flex flex-column gap-3 pb-3">
            {availableTimes.map((time) => {
              const availableTimeAsDayjs = DateUtils.fromDisplayFormat(
                `${
                  DateUtils.toDisplayFormat(day, timezone).split(" ")[0]
                } ${time}`,
                timezone
              );
              const isBeforeToday =
                setEntity === undefined &&
                availableTimeAsDayjs?.isBefore(DateUtils.now(timezone));
              const isSelected =
                selectedTimeDates?.includes(
                  DateUtils.toDBFormat(availableTimeAsDayjs, timezone) || ""
                ) || false;
              const isDisabled =
                (schedule.disabledTimes || {})[day.getDay()]?.includes(time) ||
                schedule.disabledDays?.includes(day.getDay()) ||
                isBeforeToday ||
                (selectedTimeDates?.length || 0) >= 3 ||
                ("appointments" in entity &&
                  (entity.appointments || []).some((appointment) => {
                    const appointmentTime = DateUtils.fromDBFormat(
                      appointment.option1 ||
                        appointment.option2 ||
                        appointment.option3 ||
                        appointment.selectedOption,
                      timezone
                    );
                    return (
                      appointmentTime &&
                      DateUtils.toDBFormat(appointmentTime, timezone) ===
                        DateUtils.toDBFormat(availableTimeAsDayjs, timezone) &&
                      (appointment.status ===
                        AppointmentStatus.WAITING_PROVIDER ||
                        appointment.status === AppointmentStatus.CONFIRMED)
                    );
                  }));

              return (
                <TimeSelectionCell
                  className={
                    isSelected ? "selected" : isDisabled ? "disabled" : ""
                  }
                  key={time}
                  onClick={() => {
                    if (
                      !isDisabled &&
                      !schedule.disabledDays?.includes(day.getDay())
                    ) {
                      handleClickSelectionCell(day, time);
                    }
                  }}
                >
                  {time}
                </TimeSelectionCell>
              );
            })}
          </div>
        </div>
      ))}
    </Container>
  );
};
