export const BookingForm = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit: (e: any) => void;
}) => (
  <form
    onSubmit={onSubmit}
    className="flex flex-col w-full gap-2 justify-center items-center"
  >
    {children}
  </form>
);
