import { Loading } from "../components/Pages/Loading";
import { useAuth } from "../contexts/auth.context";

import { AppRoutes } from "./app.routes";
import { AuthRoutes } from "./auth.routes";

export const ApplicationRoutes = () => {
  const { signed, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return signed ? <AppRoutes /> : <AuthRoutes />;
};
