import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button, Input, Textarea } from "@nextui-org/react";
import { Check, Star } from "lucide-react";
import { useSubmitReview } from "../../../hooks/queries/useSubmitReview";
import { Appointment } from "../../../models/Appointment";
import { useAuth } from "../../../contexts/auth.context";

export const Review = ({ appointment }: { appointment: Appointment }) => {
  const { currentUser } = useAuth();
  const [rating, setRating] = useState(0);
  const [name, setName] = useState(currentUser.name as string);
  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const submitReviewMutation = useSubmitReview();

  const handleSubmit = () => {
    submitReviewMutation.mutate(
      {
        appointmentId: appointment.id as string,
        rating,
        comment,
        userId: appointment.userId as string,
        avatarUrl: currentUser.photoURL as string,
        username: (currentUser.name as string) || name,
        providerId: appointment.providerId as string,
      },
      {
        onSuccess: () => setIsSubmitted(true),
      }
    );
  };

  const isSubmitDisabled =
    rating === 0 ||
    (!currentUser.name && !name) ||
    submitReviewMutation.isPending ||
    isSubmitted;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col justify-center bg-white items-start rounded-large gap-4 w-full"
    >
      <div className="flex flex-col items-start w-full gap-2">
        <motion.div className="flex mx-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <motion.div
              key={star}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            >
              <Star
                size={24}
                className={`cursor-pointer ${
                  star <= rating
                    ? "text-primary-500 fill-primary-500"
                    : "text-default-500"
                }`}
                onClick={() => !isSubmitted && setRating(star)}
              />
            </motion.div>
          ))}
        </motion.div>
        {!currentUser.name && (
          <Input
            size="lg"
            radius="md"
            variant="bordered"
            placeholder="Digite o seu Nome"
            value={name}
            onValueChange={(value) => !isSubmitted && setName(value)}
            isDisabled={isSubmitted}
          />
        )}
        <Textarea
          placeholder="Deixe seu comentário (opcional)"
          variant="bordered"
          value={comment}
          onChange={(e) => !isSubmitted && setComment(e.target.value)}
          minRows={3}
          radius="md"
          size="lg"
          className="w-full"
          isDisabled={isSubmitted}
        />
        <Button
          startContent={
            <AnimatePresence mode="wait">
              {isSubmitted && (
                <motion.div
                  key="check"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                >
                  <Check />
                </motion.div>
              )}
            </AnimatePresence>
          }
          color={isSubmitted ? "success" : "primary"}
          onClick={handleSubmit}
          isLoading={submitReviewMutation.isPending}
          isDisabled={isSubmitDisabled}
          className="w-full"
        >
          <AnimatePresence mode="wait">
            <motion.span
              key={isSubmitted ? "success" : "submit"}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              {isSubmitted ? "Avaliação Enviada!" : "Enviar Avaliação"}
            </motion.span>
          </AnimatePresence>
        </Button>
      </div>
    </motion.div>
  );
};
