import { useQuery } from "@tanstack/react-query";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";

const fetchReviews = async (providerId: string) => {
  const reviewsRef = collection(fireStoreDb, "reviews");
  const q = query(
    reviewsRef,
    where("providerId", "==", providerId),
    orderBy("rating", "desc")
  );

  const querySnapshot = await getDocs(q);
  const reviews: any = [];
  querySnapshot.forEach((doc) => {
    reviews.push({ id: doc.id, ...doc.data() });
  });

  return reviews;
};

export const useReviewsQuery = (providerId: string) => {
  return useQuery({
    queryKey: ["providerReviews", providerId],
    queryFn: () => fetchReviews(providerId),
    enabled: !!providerId,
  });
};
