import { Chip } from "@nextui-org/react";
import { getColorByStatusCode } from "../../../utils/getColorByStatusCode";
import { AppointmentStatus } from "../../../models/Appointment";

export const StatusChip = ({
  status,
  description,
  startContent,
}: {
  status: AppointmentStatus;
  description: string;
  startContent: JSX.Element;
}) => {
  const { color } = getColorByStatusCode(status);
  return (
    <Chip variant="bordered" color={color} startContent={startContent}>
      {description}
    </Chip>
  );
};
