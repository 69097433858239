import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox, Add } from "@mui/icons-material";
import { CustomModal } from "../CustomModalInner";
import {
  useAddService,
  useMedicalServicesQuery,
} from "../../hooks/queries/useMedicalServicesQuery";
import { toast } from "react-toastify";

export interface ServiceAutoCompleteProps {
  value: string[];
  setValue: (value: string[]) => void;
}

export const ServiceAutoComplete: React.FC<ServiceAutoCompleteProps> = ({
  value,
  setValue,
}) => {
  const [addServiceModalActive, setAddServiceModalActive] = useState(false);
  const [newService, setNewService] = useState<string>("");

  // Query to fetch services
  const {
    data: medicalServicesQuery,
    isLoading,
    refetch,
  } = useMedicalServicesQuery();

  // Mutation for adding a new service
  const { mutateAsync: addService, isPending: isAddingService } =
    useAddService();

  const handleAddService = async () => {
    if (!newService) return;

    try {
      await addService(newService);
      setValue([...value, newService]);
      setNewService("");
      setAddServiceModalActive(false);
      refetch();
      toast.success("Novo serviço adicionado com sucesso!");
    } catch (error) {
      console.error("Error adding new service: ", error);
      toast.error("Erro ao adicionar novo serviço");
    }
  };

  const services =
    medicalServicesQuery?.filter((service) => service.type === "service") || [];

  return (
    <div className="flex items-center gap-2">
      <Autocomplete
        multiple
        options={services}
        disableCloseOnSelect
        getOptionLabel={(option) => option.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.id}
          </li>
        )}
        value={services.filter((service) => value.includes(service.id)) || []}
        onChange={(_event, newValue) => {
          setValue(newValue.map((v) => v.id));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Serviços"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        fullWidth
        loading={isLoading}
      />
      <div>
        <IconButton
          color="primary"
          onClick={() => setAddServiceModalActive(true)}
          disabled={isLoading || isAddingService}
        >
          <Add />
        </IconButton>
      </div>
      <CustomModal
        open={addServiceModalActive}
        onClose={() => setAddServiceModalActive(false)}
      >
        <div className="p-5 d-flex flex-column gap-3">
          <TextField
            autoComplete="new-password"
            required
            label="Novo Serviço"
            variant="outlined"
            value={newService}
            onChange={({ target }) => setNewService(target.value)}
            disabled={isAddingService}
          />
          <Button
            variant="contained"
            onClick={handleAddService}
            disabled={isAddingService || !newService}
          >
            {isAddingService ? <CircularProgress size={24} /> : "Adicionar"}
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};
