import { Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { AdminClinics } from "../pages/AdminClinics";
import { AdminProviders } from "../pages/AdminProviders";
import { Home } from "../components/Pages/Home";
import { Navbar } from "../components/Organisms/Navbar";
import { MyAppointments } from "../components/Pages/MyAppointments";
import { Appointment } from "../components/Pages/Appointment";
import { Loading } from "../components/Pages/Loading";
import { ProtectedRoutes } from "../components/Organisms/ProtectedRoutes";
import { Profile } from "../components/Pages/Profile";
import { ProviderBooking } from "../components/Pages/ProviderBooking";
import { ClinicBooking } from "../components/Pages/ClinicBooking";

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes location={location}>
          <Route path="/appointments/" element={<MyAppointments />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Profile />} />
          <Route path="/sign-up" element={<Profile />} />

          <Route element={<ProtectedRoutes allowedRoles={["customer"]} />}>
            <Route index path="/" element={<Home />} />
            <Route
              path="/providers/:providerId/booking"
              element={<ProviderBooking />}
            />
            <Route
              path="/clinics/:clinicId/booking"
              element={<ClinicBooking />}
            />
          </Route>

          <Route
            element={
              <ProtectedRoutes allowedRoles={["customer", "provider"]} />
            }
          >
            <Route
              path="/appointments/:appointmentId"
              element={<Appointment />}
            />
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["admin"]} />}>
            <Route path="/admin/clinics" element={<AdminClinics />} />
            <Route path="/admin/providers" element={<AdminProviders />} />
          </Route>
        </Routes>
      </Suspense>
      <Navbar></Navbar>
    </>
  );
};
