// import { useDisclosure } from "@nextui-org/react";
// import { useAuth } from "../../../contexts/auth.context";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { ProviderCard } from "../../Molecules/ProviderCard";
// import { PhoneVerificationModal } from "../PhoneVerificationModal";
import { useNavigate } from "react-router-dom";

export const ProvidersList = ({
  providers,
  isGeneralSearch,
  medicalService,
}: {
  providers: ProviderInformation[];
  isGeneralSearch: boolean;
  medicalService: string;
}) => {
  // const { currentUser } = useAuth();
  const navigate = useNavigate();
  // const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleProviderClick = (provider: ProviderInformation) => {
    // if (currentUser.phoneConfirmed === false) {
    // onOpen();
    // } else {
    navigate(`/providers/${provider.id}/booking`);
    // }
  };

  return (
    <div className="flex max-lg:flex-col flex-wrap lg:max-w-5xl gap-2 items-center justify-center max-md:pb-20">
      {providers.map((provider) => (
        <ProviderCard
          key={provider.id}
          isGeneralSearch={isGeneralSearch}
          medicalService={medicalService}
          provider={provider}
          onCardClick={() => handleProviderClick(provider)}
        />
      ))}

      {/* {currentUser.phoneConfirmed === false && (
        <PhoneVerificationModal isOpen={isOpen} onOpenChange={onOpenChange} />
      )} */}
    </div>
  );
};
