import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, query, where } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { Appointment } from "../../models/Appointment";

interface UseAppointmentsQueryParams {
  clinicId?: string;
  providerId?: string;
}

export const useAppointmentsQuery = ({
  clinicId,
  providerId,
}: UseAppointmentsQueryParams) => {
  return useQuery<Appointment[], Error>({
    queryKey: ["appointments", { clinicId, providerId }],
    queryFn: async () => {
      const appointmentsRef = collection(fireStoreDb, "appointments");
      let q;

      if (clinicId) {
        q = query(appointmentsRef, where("clinicId", "==", clinicId));
      } else if (providerId) {
        q = query(appointmentsRef, where("providerId", "==", providerId));
      } else {
        throw new Error("Either clinicId or providerId must be provided");
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          } as Appointment)
      );
    },
    enabled: true, // Always enable the query
  });
};
