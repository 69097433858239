import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { toast } from "react-toastify";
import { useConfirmAppointment } from "../../../hooks/queries/useConfirmAppointment";
import { queryClient } from "../../../libs/queryClient";

export const ConfirmAppointmentModal: React.FC<{
  isOpen: boolean;
  date: string;
  fullDate: string;
  time: string;
  appointmentId: string;
  onOpenChange: () => void;
}> = ({ isOpen, date, fullDate, time, appointmentId, onOpenChange }) => {
  const confirmAppointmentMutation = useConfirmAppointment();

  const confirmAppointment = (onClose: () => void) => {
    confirmAppointmentMutation.mutate(
      { id: appointmentId, date: fullDate },
      {
        onSuccess: () => {
          toast.success("Agendamento salvo com sucesso!");
          onClose();
          queryClient.invalidateQueries({
            queryKey: ["appointment", appointmentId],
          });
        },
        onError: (error) => {
          toast.error("Erro ao salvar agendamento. Tente novamente");
          console.error("Error confirming appointment: ", error);
        },
      }
    );
  };

  return (
    <Modal
      backdrop="blur"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      classNames={{
        backdrop: "bg-black/75",
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className=" text-neutral-900 max-md:text-center max-md:w-full">
              Deseja confirmar esse agendamento para
            </ModalHeader>
            <ModalBody className="flex flex-col items-center">
              <div className="bg-warning-100/50 rounded-xl p-2 flex flex-col justify-center items-center gap-1 w-fit">
                <h2 className={`text-warning-900 text-2xl font-semibold`}>
                  {date}
                </h2>
                <span
                  className={`text-warning-900 text-sm leading-none font-semibold`}
                >
                  {time}
                </span>
              </div>
            </ModalBody>
            <ModalFooter className="gap-4">
              <Button
                color="danger"
                variant="light"
                onPress={onClose}
                className="font-medium"
              >
                Cancelar
              </Button>
              <Button
                color="secondary"
                variant="solid"
                className="font-medium"
                onPress={() => confirmAppointment(onClose)}
                isLoading={confirmAppointmentMutation.isPending}
                isDisabled={confirmAppointmentMutation.isPending}
              >
                Confirmar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
