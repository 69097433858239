import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { CustomModal } from "../CustomModalInner";
import { toast } from "react-toastify";
import { functions } from "../../services/firebase/firebase";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";

interface DetailsForUserCreation {
  email: string;
  password: string;
}

interface AddNewProviderLogicProps {
  onProviderAdded: (providerId: string) => void;
}

export const AddNewProviderLogic: React.FC<AddNewProviderLogicProps> = ({
  onProviderAdded,
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<DetailsForUserCreation>({
    email: "",
    password: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const createNewProviderUserFunc = httpsCallable<
        DetailsForUserCreation,
        string
      >(functions, "createNewProviderUser");

      const result: HttpsCallableResult<string> =
        await createNewProviderUserFunc(userDetails);

      onProviderAdded(result.data);
      setUserDetails({ email: "", password: "" });
      setModal(false);
      toast.success("Profissional cadastrado com sucesso!");
    } catch (error: any) {
      console.error(error.message);
      let errorMessage = "Ocorreu um erro ao cadastrar o profissional.";

      if (error.code === "functions/already-exists") {
        errorMessage =
          "Este email já está em uso. Por favor, use um email diferente.";
      } else if (error.code === "functions/invalid-argument") {
        errorMessage =
          "Os dados fornecidos são inválidos. Verifique o email e a senha.";
      }

      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModal(true)}
      >
        Adicionar novo
      </Button>

      <CustomModal
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="p-5 d-flex gap-3 flex-column">
          <h2>Adicionar novo profissional</h2>

          <TextField
            autoComplete="new-password"
            required
            label="Email"
            variant="outlined"
            value={userDetails.email}
            onChange={({ target }) =>
              setUserDetails({ ...userDetails, email: target.value })
            }
          />
          <TextField
            autoComplete="new-password"
            required
            label="Senha temporária"
            variant="outlined"
            value={userDetails.password}
            onChange={({ target }) =>
              setUserDetails({ ...userDetails, password: target.value })
            }
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !userDetails.email || !userDetails.password}
          >
            {loading ? "Cadastrando..." : "Cadastrar"}
          </Button>
          <small className="text-muted text-center">
            Ao cadastrar um novo profissional, você será redirecionado para sua
            tela de edição
          </small>
        </div>
      </CustomModal>
    </div>
  );
};
