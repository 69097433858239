import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth.context";

export const EmptyAppointmentCard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  return (
    <div className="bg-default-200 rounded-large w-full p-2 flex flex-col gap-2 justify-center items-center flex-wrap">
      <h2 className="text-center">
        {currentUser.role === "provider"
          ? "Não existem agendamentos na data selecionada"
          : "Você não tem agendamentos entre as datas selecionadas"}
      </h2>
      {currentUser.role === "customer" && (
        <Button
          color="primary"
          variant="solid"
          className="font-medium max-md:w-full"
          onPress={() => navigate("/")}
        >
          Fazer um agendamento
        </Button>
      )}
    </div>
  );
};
