import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  PaymentMethod,
  PAYMENT_METHODS,
  PAYMENT_METHOD_LABELS,
} from "../../models/PaymentMethod";

interface PaymentMethodAutocompleteProps {
  value: PaymentMethod[];
  onChange: (value: PaymentMethod[]) => void;
}

const PaymentMethodAutocomplete: React.FC<PaymentMethodAutocompleteProps> = ({
  value,
  onChange,
}) => {
  return (
    <Autocomplete
      multiple
      options={PAYMENT_METHODS}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Métodos de pagamento"
          variant="outlined"
        />
      )}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      getOptionLabel={(option) => PAYMENT_METHOD_LABELS[option]}
    />
  );
};

export default PaymentMethodAutocomplete;
