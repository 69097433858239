import { Clinic } from "../models/Clinic";
import { ProviderInformation } from "../models/ProviderInformation";

export const isProviderInformation = (
  entity: ProviderInformation | Clinic
): entity is ProviderInformation => {
  return (
    "schedules" in entity &&
    !!entity.schedules &&
    "appointments" in entity.schedules
  );
};
