import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { State } from "../../models/State";

const fetchStates = async (): Promise<State[]> => {
  const { data } = await axios.get<State[]>(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
  );
  return data.map(({ id, sigla, nome }) => ({ id, sigla, nome }));
};

export const useIBGEStatesQuery = () => {
  return useQuery<State[], Error>({
    queryKey: ["states"],
    queryFn: fetchStates,
    staleTime: 30 * 24 * 60 * 60 * 1000,
  });
};
