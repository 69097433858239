import { AppointmentStatus } from "../models/Appointment";

export const tabToStatusMap: Record<string, AppointmentStatus> = {
  pending: AppointmentStatus.WAITING_PROVIDER,
  confirmed: AppointmentStatus.CONFIRMED,
  completed: AppointmentStatus.COMPLETED,
  cancelled: AppointmentStatus.CANCELLED,
};

export const statusToTabMap: Record<AppointmentStatus, string> = {
  [AppointmentStatus.WAITING_PROVIDER]: "pending",
  [AppointmentStatus.CONFIRMED]: "confirmed",
  [AppointmentStatus.COMPLETED]: "completed",
  [AppointmentStatus.CANCELLED]: "cancelled",
};
