import { Schedule } from "../../models/Schedule";
import { DateUtils } from "../../utils/dateUtils";

export function calculateAvailableTimesPerDay(
  timezone: string,
  schedule: Schedule,
  isExam: boolean,
  examId?: string,
  serviceId?: string,
  conflictingAppointments?: Set<string>
): string[] {
  if (!schedule?.initTime || !schedule?.endTime) return [];

  const init = DateUtils.fromDBFormat(schedule.initTime, timezone);
  const end = DateUtils.fromDBFormat(schedule.endTime, timezone);

  if (!init || !end) return [];

  let durationMinutes: number;

  if (isExam && examId && schedule.exams?.[examId]?.duration) {
    durationMinutes = schedule.exams[examId].duration;
  } else if (!isExam && serviceId && schedule.services?.[serviceId]?.duration) {
    durationMinutes = schedule.services[serviceId].duration;
  } else if (schedule.defaultDuration) {
    const defaultDuration = DateUtils.fromDBFormat(
      schedule.defaultDuration,
      timezone
    );
    durationMinutes = defaultDuration
      ? defaultDuration.hour() * 60 + defaultDuration.minute()
      : 60; // Default to 1 hour if parsing fails
  } else {
    durationMinutes = 60; // Default to 1 hour if no duration is specified
  }

  const availableTimes = new Set<string>();
  let currentTime = init.clone().startOf("day");
  const endOfDay = currentTime.clone().endOf("day");

  while (currentTime.isSameOrBefore(endOfDay)) {
    const timeString = currentTime.format("HH:mm");
    const dayOfWeek = currentTime.day();

    const isWithinWorkHours =
      (currentTime.isSameOrAfter(init) && currentTime.isBefore(end)) ||
      (end.isBefore(init) &&
        (currentTime.isSameOrAfter(init) || currentTime.isBefore(end)));

    if (
      isWithinWorkHours &&
      !schedule.disabledTimes?.[dayOfWeek]?.includes(timeString)
    ) {
      const slotEnd = currentTime.add(durationMinutes, "minute");

      if (slotEnd.isSameOrBefore(endOfDay)) {
        const formattedSlotTime = DateUtils.toDBFormat(currentTime, timezone);
        if (
          !conflictingAppointments ||
          !conflictingAppointments.has(formattedSlotTime as string)
        ) {
          availableTimes.add(timeString);
        }
      }
    }

    currentTime = currentTime.add(durationMinutes, "minute");
  }

  return Array.from(availableTimes).sort();
}
