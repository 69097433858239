import { Appointment } from "./Appointment";
import { City } from "./City";
import { PaymentMethod } from "./PaymentMethod";
import { ProviderInformation } from "./ProviderInformation";
import { Schedule } from "./Schedule";
import { State } from "./State";

export enum ClinicTypeEnum {
  Comum = "Comum",
  Autonomous = "Autônoma",
}

// TODO: Confirmar se dados estão de acordo com o esperado (regra de negócios)
export interface Clinic {
  id?: string;

  type?: ClinicTypeEnum;
  name?: string;
  cnpj?: string;

  state?: State;
  city?: City;

  email?: string;
  imageUrl?: string | null;
  address?: string;
  phoneNumber?: string;
  timezone: string;

  relatedProviders?: ProviderInformation[];
  offeredSpecialties?: string[];
  offeredExams?: string[];
  acceptedHealthInsurances?: string[];
  acceptedPaymentMethods?: PaymentMethod[];
  openingHours?: string;

  schedules?: {
    exams?: Schedule | undefined;
  };

  manager?: {
    name?: string;
    email?: string;
    cpf?: string;
    phoneNumber?: string;
  };

  appointments?: Appointment[];
}
