import { useQuery } from "@tanstack/react-query";
import { Appointment, AppointmentStatus } from "../../models/Appointment";
import { doc, getDoc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";

export const useAppointmentDetailsQuery = (appointmentId: string) => {
  return useQuery<Appointment, Error>({
    queryKey: ["appointment", appointmentId],
    queryFn: async () => {
      const docRef = doc(fireStoreDb, "appointments", appointmentId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          status: data.status as AppointmentStatus,
          providerId: data.providerId,
          clinicId: data.clinicId,
          userId: data.userId,
          examId: data.examId,
          serviceId: data.serviceId,
          option1: data.option1,
          option2: data.option2,
          option3: data.option3,
          selectedOption: data.selectedOption,
          paymentMethod: data.paymentMethod,
          healthInsurance: data.healthInsurance,
          reason: data.reason,
          hasReview: data.hasReview,
        } as Appointment;
      }
      throw new Error("Appointment not found");
    },
    enabled: !!appointmentId,
  });
};
